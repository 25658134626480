import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Box,
  TextField,
  Card,
  Checkbox,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Dialog } from "@material-ui/core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../../.././../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../.././../components/Loaders/Loader";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
// import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import ConfirmModal from "../../../../../.././../components/Models/ConfirmationModal";
import styled from "styled-components/macro";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const LocalPages = ({
  setTouchedValue,
  isLoading,
  localPagesData,
  setLocalPagesData,
  clickAndConnectIsValid,
  deliverooIsValid,
  deliverooRequired,
  justEatIsValid,
  justEatRequired,
  uberEatsIsValid,
  uberEatsRequired,
  menuLinkRequired,
  menuLinkIsValid,
  clickAndConnectRequired,
  slugIsValid,
  slugRequired,
  setSlugRequired,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container className={classes.customFieldMain}>
        <Grid container>
          {isLoading ? (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.customFieldLoad}
              >
                <Loader />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container className={classes.customFieldSlug}>
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <Box className={classes.categoriesSocialBox}>
                    <Typography className={classes.fieldTextSocial}>
                      {"Slug"}
                      {""} <span style={{ color: "#FC3652" }}>*</span>
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"oud-zuid"}
                    name="slug"
                    variant="outlined"
                    //   label="MenuLink"
                    value={localPagesData?.slug}
                    className={classes.CustomFieldWidth}
                    error={slugRequired}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        slug: e.target.value,
                      });
                      setTouchedValue(true);
                      if (e.target.value.length > 0) {
                        setSlugRequired(false);
                      } else {
                        setSlugRequired(true);
                      }
                    }}
                    //  helperText={slugRequired && t("menu_link_required")}
                    helperText={slugRequired && t("slug_required")}
                  />
                </Grid>

                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  className={classes.customFieldContain}
                >
                  <Box className={classes.categoriesSocialBox}>
                    <Typography className={classes.fieldTextSocial}>
                      {t("display_name")}
                    </Typography>
                    <BootstrapTooltip
                      title={
                        <h1 style={{ fontSize: "12px" }}>
                          {t("display_name_tooltip")}
                        </h1>
                      }
                    >
                      <ErrorOutlineIcon
                        sx={{
                          color: "#1B2430",
                          padding: "0px",
                          cursor: "pointer",
                        }}
                      />
                    </BootstrapTooltip>
                  </Box>

                  <TextField
                    placeholder={t("display_name_placeholder")}
                    name="displayName"
                    variant="outlined"
                    value={localPagesData?.displayName}
                    className={classes.CustomFieldWidth}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        displayName: e.target.value,
                      });
                      setTouchedValue(true);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  className={classes.customFieldContain}
                >
                  <Box className={classes.categoriesSocialBox}>
                    <Typography className={classes.fieldTextSocial}>
                      {t("menu_link")}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    name="menuLink"
                    variant="outlined"
                    //   label="MenuLink"
                    value={localPagesData?.menuLink}
                    className={classes.CustomFieldWidth}
                    error={menuLinkRequired}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        menuLink: e.target.value,
                      });
                      setTouchedValue(true);
                      /*   if (e.target.value.length > 0) {
                            setMenuLinkRequred(false);
                          } else {
                            setMenuLinkRequred(true);
                          } */
                    }}
                    // helperText={menuLinkRequired && t("menu_link_required")}
                    helperText={
                      (menuLinkIsValid === false &&
                        localPagesData?.menuLink?.length > 0 && (
                          <span style={{ color: "red" }}>
                            {" "}
                            {t("Please_enter_valid_url")}
                          </span>
                        )) ||
                      (menuLinkRequired && t("menu_link_required"))
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                container
                className={classes.customFieldContaines2}
                spacing={4}
              >
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <Typography className={classes.fieldTextSocial}>
                    {t("Delivery_Options")}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <Box className={classes.categoriesSocialBox}>
                    {" "}
                    <Typography className={classes.LocalPagesModalSubheader}>
                      {"ClickAndConnect"}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    // AndConnect"
                    name="clickAndConnect"
                    variant="outlined"
                    // label="ClickAndConnect"
                    value={localPagesData?.clickAndConnect}
                    className={classes.CustomFieldWidth}
                    error={clickAndConnectRequired}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        clickAndConnect: e.target.value,
                      });
                      setTouchedValue(true);
                      /*  if (e.target.value.length > 0) {
                          setClickAndConnectRequired(false);
                        } else {
                          setClickAndConnectRequired(true);
                        } */
                    }}
                    helperText={
                      (clickAndConnectIsValid === false &&
                        localPagesData?.clickAndConnect?.length > 0 && (
                          <span style={{ color: "red" }}>
                            {" "}
                            {t("Please_enter_valid_url")}
                          </span>
                        )) ||
                      (clickAndConnectRequired &&
                        t("click_and_connect_required"))
                    }
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <Box className={classes.categoriesSocialBox}>
                    {" "}
                    <Typography className={classes.LocalPagesModalSubheader}>
                      {"Deliveroo"}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    // AndConnect"
                    name="deliveroo"
                    variant="outlined"
                    // label="Deliveroo"
                    value={localPagesData?.deliveroo}
                    className={classes.CustomFieldWidth}
                    error={deliverooRequired}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        deliveroo: e.target.value,
                      });
                      setTouchedValue(true);
                      /*   if (e.target.value.length > 0) {
                          setDeliverooRequired(false);
                        } else {
                          setDeliverooRequired(true);
                        } */
                    }}
                    helperText={
                      (deliverooIsValid === false &&
                        localPagesData?.deliveroo?.length > 0 && (
                          <span style={{ color: "red" }}>
                            {" "}
                            {t("Please_enter_valid_url")}
                          </span>
                        )) ||
                      (deliverooRequired && t("deliver_required"))
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.customFieldContaines}
                spacing={4}
              >
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <Box className={classes.categoriesSocialBox}>
                    {" "}
                    <Typography className={classes.LocalPagesModalSubheader}>
                      {"JustEat"}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    // AndConnect"
                    name="justEat"
                    variant="outlined"
                    // label="JustEat"
                    value={localPagesData?.justEat}
                    className={classes.CustomFieldWidth}
                    error={justEatRequired}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        justEat: e.target.value,
                      });
                      setTouchedValue(true);
                      /*   if (e.target.value.length > 0) {
                          setJustEatRequired(false);
                        } else {
                          setJustEatRequired(true);
                        } */
                    }}
                    helperText={
                      (justEatIsValid === false &&
                        localPagesData?.justEat?.length > 0 && (
                          <span style={{ color: "red" }}>
                            {" "}
                            {t("Please_enter_valid_url")}
                          </span>
                        )) ||
                      (justEatRequired && t("just_eat_required"))
                    }
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <Box className={classes.categoriesSocialBox}>
                    {" "}
                    <Typography className={classes.LocalPagesModalSubheader}>
                      {"UberEats"}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    // AndConnect"
                    name="uberEats"
                    variant="outlined"
                    // label="UberEat"
                    value={localPagesData?.uberEats}
                    className={classes.CustomFieldWidth}
                    error={uberEatsRequired}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        uberEats: e.target.value,
                      });
                      setTouchedValue(true);
                      /*  if (e.target.value.length > 0) {
                          setUberEatsRequired(false);
                        } else {
                          setUberEatsRequired(true);
                        } */
                    }}
                    helperText={
                      (uberEatsIsValid === false &&
                        localPagesData?.uberEats?.length > 0 && (
                          <span style={{ color: "red" }}>
                            {" "}
                            {t("Please_enter_valid_url")}
                          </span>
                        )) ||
                      (uberEatsRequired && t("uber_eats_required"))
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.customFieldContaines}
                spacing={4}
              >
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <Box className={classes.categoriesSocialBox}>
                    <Typography className={classes.LocalPagesModalSubheader}>
                      {"Pedidosya"}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    name="pedidosYa"
                    variant="outlined"
                    value={localPagesData?.pedidosYa}
                    className={classes.CustomFieldWidth}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        pedidosYa: e.target.value,
                      });
                      setTouchedValue(true);
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <Box className={classes.categoriesSocialBox}>
                    <Typography className={classes.LocalPagesModalSubheader}>
                      {"Rappi"}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    name="rappi"
                    variant="outlined"
                    value={localPagesData?.rappi}
                    className={classes.CustomFieldWidth}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        rappi: e.target.value,
                      });
                      setTouchedValue(true);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.customFieldContaines}
                spacing={4}
              >
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <Box className={classes.categoriesSocialBox}>
                    <Typography className={classes.LocalPagesModalSubheader}>
                      {"Grubhub"}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    name="grubHub"
                    variant="outlined"
                    value={localPagesData?.grubHub}
                    className={classes.CustomFieldWidth}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        grubHub: e.target.value,
                      });
                      setTouchedValue(true);
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <Box className={classes.categoriesSocialBox}>
                    <Typography className={classes.LocalPagesModalSubheader}>
                      {"DoorDash"}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    name="doorDash"
                    variant="outlined"
                    value={localPagesData?.doorDash}
                    className={classes.CustomFieldWidth}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        doorDash: e.target.value,
                      });
                      setTouchedValue(true);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.customFieldContaines}
                spacing={4}
              >
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <Box className={classes.categoriesSocialBox}>
                    <Typography className={classes.LocalPagesModalSubheader}>
                      {"Wolt"}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    name="wolt"
                    variant="outlined"
                    value={localPagesData?.wolt}
                    className={classes.CustomFieldWidth}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        wolt: e.target.value,
                      });
                      setTouchedValue(true);
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <Box className={classes.categoriesSocialBox}>
                    <Typography className={classes.LocalPagesModalSubheader}>
                      {"ifood"}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    name="iFood"
                    variant="outlined"
                    value={localPagesData?.iFood}
                    className={classes.CustomFieldWidth}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        iFood: e.target.value,
                      });
                      setTouchedValue(true);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.customFieldContaines}
                spacing={4}
              >
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <Box className={classes.categoriesSocialBox}>
                    <Typography className={classes.LocalPagesModalSubheader}>
                      {"E-Food"}
                    </Typography>
                  </Box>

                  <TextField
                    placeholder={"http://new.helloworld.com/"}
                    name="eFood"
                    variant="outlined"
                    value={localPagesData?.eFood}
                    className={classes.CustomFieldWidth}
                    onChange={(e) => {
                      setLocalPagesData({
                        ...localPagesData,
                        eFood: e.target.value,
                      });
                      setTouchedValue(true);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LocalPages;
