import React, { useState, useRef, useEffect } from "react";

import {
  Grid,
  Typography,
  TextField,
  Box,
  Autocomplete,
  Checkbox,
  TableContainer,
  Paper,
  Table,
  TableBody,
  IconButton,
  Button,
  createFilterOptions,
  CircularProgress,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import styled from "styled-components/macro";

import AddIcon from "@mui/icons-material/Add";
import * as XLSX from "xlsx";
import { ThemeProvider } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import Title from "../../../../../../components/CustomComponents/title/index.js";
import AddNewUsersFeatures from "./AddNewUsersFeatures.js";
import CommonButton from "../../../../../../components/CustomComponents/Buttons/CommonButton.js";
import CustomizedTableCell from "../../../../../../components/CustomComponents/table/tableCell.js";
import CustomizedTableHead from "../../../../../../components/CustomComponents/table/tableHead.js";
import CustomizedTableRow from "../../../../../../components/CustomComponents/table/tableRow.js";
import NoLocationFound from "../../../../../../components/CustomComponents/Errors/NoLocationFound.js";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useStyles } from "../../../styles/style.js";
import FetchEmailsModel from "./FetchEmailsModel.js";
import AddedEmailModel from "./AddedEmailModel.js";
import ShareableLinkModel from "./ShareableLinkModel.js";
import { api } from "../../../../../../contexts/JWTContext.js";
import Loader from "../../../../../../components/Loaders/Loader.js";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useDateFormat from "../../../../../../hooks/useDateFormat.js";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="bottom"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

function AddNewUser({ theme }) {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const textArea = useRef();
  const classes = useStyles();
  const location = useLocation();
  const { page } = location.state || {};
  const [manageLocation, setManageLocation] = useState("");
  const [dateFormat, setDateFormat] = useState({ label: "DD/MM/YYYY" });
  const [dataArray, setDataArray] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [addNewUserEmials, setAddNewUserEmails] = useState("");
  const [validEmailsArray, setValidEmailsArray] = useState([]);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [totalEmailsArr, setTotalEmailsArr] = useState([]);
  const [totalModalEmailsArr, setTotalModalEmailsArr] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAllCheckedModal, setSelectAllCheckedModal] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [duplicateRole, setDuplicateRole] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [selectedRoleError, setSelectedRoleError] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [emailsError, setEmailsError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [locationValue, setLocationValue] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [fileLoading, setFileLoading] = useState(false);
  const [roleNameError, setRoleNameError] = useState(false);
  const [showRoleName, setShowRoleName] = useState(false);
  const [isModalEmailsSent, setIsModalEmailsSent] = useState(true);
  const [sameNameError, setSameNameError] = useState(false);
  const [permissionsArr, setPermissionsArr] = useState([]);
  const [submitUserLoading, setSubmitUserLoading] = useState(false);
  const [validateEmailsError, setValidateEmailsError] = useState([]);
  const [singleRole, setSingleRole] = useState({
    permissions: [],
  });
  const [openAddedEmailModal, setOpenAddedEmailModal] = useState(false);
  const [companyFeatures, setAllCompanyFeatures] = useState([]);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const userData = JSON.parse(localStorage.getItem("user"));

  const [allRoles, setAllRoles] = useState(null);
  const [inviteLink, setInviteLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openShareableLinkModel, setOpenShareableLinkModel] = useState(false);
  const [addedEmails, setAddedEmails] = useState([]);
  const [validEmailLoading, setValidEmailLoading] = useState(false);
  const [openInviteEmailModal, setOpenInviteEmailModal] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [disableButton, setDisableButton] = useState(false);
  const filter = createFilterOptions();
  const [checkboxes, setCheckboxes] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false,
  });
  const [allGroups, setAllGroups] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [oldGroups, setOldGroups] = useState([]);
  const [selectAllGroups, setSelectAllGroups] = useState(false);

  const manangeLocations = [
    { label: "All Locations", displayName: t("All_Locations") },
    { label: "Specific Locations", displayName: t("specificLocation") },
    { label: "Groups", displayName: t("groups_label") },
  ];

  const dateFormats = [
    { label: "D/M/YYYY" },
    { label: "YYYY-MM-DD" },
    { label: "DD/MM/YYYY" },
    { label: "MM/DD/YYYY" },
    { label: "DD.MM.YYYY" },
  ];

  useEffect(() => {
    const permissionFound = permissionsArr?.filter(
      (item) => item?.permissions?.length > 0
    );
    if (permissionFound?.length <= 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [permissionsArr]);

  /*   useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleValidateEmails(false);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [totalEmailsArr]); */

  useEffect(() => {
    getAllLocations();
    getAllCompanyFeatures();
    getAllRoles();
    getAllLocationGroups();
  }, []);

  useEffect(() => {
    if (selectedRole !== null) {
      getSingleRole();
    }
  }, [selectedRole]);

  const getAllLocationGroups = async () => {
    setIsLoading(true);
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.get(`groups?pageNumber=1&limit=1000`);
      if (res.status === 200) {
        setAllGroups(res?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setAllGroups(null);
    }
  };

  const getSingleRole = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/companyRoles/${selectedRole?.id}`);
      if (res.status === 200) {
        setLoading(false);

        setSingleRole({
          permissions: res?.data?.data?.CompanyRolesPermissions,
        });
      }
    } catch (error) {}
  };

  const getAllRoles = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `companyRoles?companyId=${companyData?.id}&pageNumber=1&limit=1000`
      );
      if (res.status === 200) {
        setAllRoles(res?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setAllRoles(null);
    }
  };

  const getAllCompanyFeatures = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/companyFeatures/${companyData?.id}/company`);
      if (res.status === 200) {
        const filteredFeatures =
          res?.data?.data?.filter((feature) => feature.name !== "Reports") ||
          []; // filtering reports
        setAllCompanyFeatures(filteredFeatures);
        setLoading(false);
      }
    } catch (error) {
      setAllCompanyFeatures([]);
      setLoading(false);
    }
  };

  const getAllLocations = async () => {
    try {
      setLocationLoading(true);
      const res = await api.get(`/location/search`, {});
      if (res.status === 200) {
        setLocationLoading(false);
        if (res?.data?.data?.results?.length > 0) {
          let locationIds = [];
          setAllLocations(res?.data?.data?.results);
          if (res?.data?.data?.results?.length > 0) {
            setManageLocation({
              label: "All Locations",
              displayName: t("All_Locations"),
            });
            res?.data?.data?.results?.map((item) => {
              locationIds?.push(item);
            });
            setLocationValue(locationIds);
          }
        } else {
          setAllLocations(null);
        }
      }
    } catch (error) {
      setAllLocations(null);
      setLocationLoading(false);
    }
  };

  useEffect(() => {
    const combinedArray = [...selectedItems, ...validEmailsArray];

    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];

    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    setTotalEmailsArr(distinctEmails);
  }, [selectedItems, validEmailsArray]);

  useEffect(() => {
    // Combine the arrays
    const combinedArray = [...dataArray];
    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];
    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );
    setSelectedItems(distinctEmails);
    setTotalModalEmailsArr(distinctEmails);
  }, [dataArray]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContents = e.target.result;
        let data;
        if (file.type === "text/csv") {
          const lines = fileContents.split("\n");
          data = lines.map((line) => line.replace("\r", "").split(","));
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const workbook = XLSX.read(fileContents, { type: "binary" });
          const sheetNames = workbook.SheetNames;
          data = [];
          sheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            data.push(...jsonData);
          });
        } else {
          return;
        }
        const flatData = data.flat();

        setTimeout(() => {
          setFileLoading(false);
        }, 1000);
        setDataArray(flatData);
      };
      setFileLoading(true);

      if (file.type === "text/csv") {
        reader.readAsText(file);
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        reader.readAsBinaryString(file);
      }
      setOpenEmailModal(true);
      setSelectedFile(file.name);
    } else {
      setTimeout(() => {
        setFileLoading(false);
      }, 1000);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleSubmitOk = () => {
    const combinedArray = [...selectedItems, ...validEmailsArray];
    //
    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];
    //
    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    if (distinctEmails?.length > 0) {
      setTotalEmailsArr(distinctEmails);
    }
    handleCloseEmailsModal();
    if (selectedItems.length <= 0) {
      setDataArray([]);
      setSelectedFile(null);
    }
    handleValidateEmails(true);
  };

  const handleOkAddedEmails = () => {
    setOpenAddedEmailModal(false);
    navigate("/user/accounts-overview", {
      state: { tabIndex: 1, page: page },
    });
  };

  const handleAddNewUserEmailsChange = (value) => {
    // if (!value) {
    //   setEmailsError(true);
    // } else {
    //   setEmailsError(false);
    // }
    const formattedValue = value.replace(/,\s*/g, ",\n");
    setAddNewUserEmails(formattedValue);

    const emailsArray = formattedValue.split(",");
    const validEmailsArray = [];

    // Validate each email address and push the valid ones into the array
    for (let i = 0; i < emailsArray.length; i++) {
      const email = emailsArray[i].trim();
      if (isValidEmail(email)) {
        validEmailsArray.push(email);
        setValidEmailsArray(validEmailsArray);
      }
    }

    const combinedArray = [...selectedItems, ...validEmailsArray];

    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];

    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    setTotalEmailsArr(distinctEmails);
    handleValidateEmailsAfterAdding(distinctEmails);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleRemoveFile = () => {
    setDataArray([]);
    setSelectedFile(null);
    // setTotalEmailsArr([]);
    // setValidEmailsArray([]);
    setSelectedItems([]);
    setValidateEmailsError([]);
  };

  const handleCloseEmailsModal = () => {
    document.getElementById("file-email").value = "";
    setOpenEmailModal(false);
    // setDataArray([]);
    //  setSelectedFile(null);
    // setTotalEmailsArr([]);
    // setValidEmailsArray([]);
    //  setSelectedItems([]);
    setSelectAllCheckedModal(false);
  };

  const handleCancelEmailsModal = () => {
    document.getElementById("file-email").value = "";
    setOpenEmailModal(false);
    if (selectedItems.length <= 0) {
      setDataArray([]);
      setSelectedFile(null);
      // setTotalEmailsArr([]);
      // setValidEmailsArray([]);
      setSelectedItems([]);
    }
    setSelectAllCheckedModal(false);
  };

  const handleCreateNewRole = () => {
    setShowFeatures(true);
    setSelectedRole(null);
    setCheckboxes({
      view: false,
      add: false,
      edit: false,
      delete: false,
    });
    setSelectAllChecked(false);
    setDuplicateRole(false);
    setShowRoleName(true);
  };
  function findDifference(arr1, arr2) {
    return arr1.filter(
      (obj1) =>
        !arr2.some((obj2) => JSON.stringify(obj1) === JSON.stringify(obj2))
    );
  }
  function filterArrayById(inputArray, targetId) {
    return inputArray.filter((item) =>
      item.groups.some((group) => group.locationId === targetId)
    );
  }

  const handleChangeGroups = (value) => {
    setSelectedGroups(value);

    let newLocations = [...locationValue];
    const results = oldGroups.filter(
      ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
    );
    if (value?.length > 0) {
      value?.map((group) => {
        group?.groups?.map((groupItem) => {
          allLocations?.map((location) => {
            if (groupItem?.locationId === location?.id) {
              newLocations?.push(location);
            } else {
              setSelectAll(false);
              results?.map((group) => {
                group?.groups?.map((groupItem) => {
                  locationValue?.map((location) => {
                    const filteredArray = filterArrayById(value, location?.id);
                    if (
                      groupItem?.locationId === location?.id &&
                      filteredArray?.length === 0
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== location?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((group) => {
        group?.groups?.map((groupItem) => {
          locationValue?.map((location) => {
            if (groupItem?.locationId === location?.id) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== location?.id
              );
            }
          });
        });
      });
    }
    const uniqueData = removeDuplicates(newLocations);
    setLocationValue(uniqueData);

    if (newLocations?.length > 0) {
      setLocationError(false);
    } else {
      setLocationError(true);
    }

    setOldGroups(value);
  };
  function checkGroupsExistence(groups, arr) {
    return groups.every((group) =>
      arr.some((elem) => elem.id === group.locationId)
    );
  }
  // Filter 'array2' based on 'groups' existence in 'array1'

  const handleChangeGroupsForLocations = (locations) => {
    if (locations?.length > 0 || selectedGroups?.length > 0) {
      const filteredArray2 = selectedGroups?.filter((item) =>
        item.groups ? checkGroupsExistence(item.groups, locations) : true
      );

      setSelectAllGroups(false);
      setSelectedGroups(filteredArray2 || []); // Make sure filteredArray2 has a value or assign an empty array if it's undefined/null
      // setLocationError(false);
    } else {
      //  setSelectAllGroups(false);
      setLocationError(true);
      setSelectedGroups([]);
    }
  };
  function removeDuplicates(arr) {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }
  const handleChangeSelectedRole = (e, value) => {
    if (!value) {
      setSelectedRoleError(true);
    } else {
      setSelectedRoleError(false);
      setShowFeatures(true);
      setDuplicateRole(false);
      setShowRoleName(true);
    }
    setShowRoleName(false);
    setSelectedRole(value);
  };

  const handleOpenSharableLinkModel = () => {
    setOpenShareableLinkModel(true);
  };

  const handleCloseSharableLinkModel = () => {
    setOpenShareableLinkModel(false);
  };
  const handleOpenAddedEmailModel = () => {
    setOpenAddedEmailModal(true);
  };
  const handleCloseAddedEmailModel = () => {
    setOpenAddedEmailModal(false);
  };

  const handleSubmitAddUser = async () => {
    handleOpenSharableLinkModel();
  };

  const onCancelAddUser = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 1, page: page },
    });
  };

  const handleChangeRoleName = (e) => {
    const value = e.target.value;
    const isSameName = allRoles?.rows?.find((item) => {
      return item.name === value;
    });
    setRoleName(value);
    if (isSameName) {
      setSameNameError(true);
    } else {
      setSameNameError(false);
    }
    if (value === "") {
      setRoleNameError(true);
    } else {
      setRoleNameError(false);
    }
  };
  const handleReopenModal = () => {
    setOpenEmailModal(true);
  };

  const handleSubmitInvite = async () => {
    let locationIds = [];

    if (locationValue?.length > 0) {
      locationValue?.map((item) => {
        locationIds?.push(item?.id);
      });
    }
    let groupIds = [];
    if (manageLocation?.label === "Groups") {
      if (selectedGroups?.length > 0) {
        selectedGroups?.map((item) => {
          groupIds.push(item?.id);
        });
      }
    } else {
      setSelectedGroups([]);
    }

    const allPermissions = permissionsArr?.reduce((accumulator, obj) => {
      return accumulator.concat(obj.permissions);
    }, []);

    setSubmitUserLoading(true);

    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.post(`companyUserRoles`, {
        emails: totalEmailsArr,
        inviteById: userData?.id,
        companyId: companyData?.id,
        dateFormat: dateFormat.label,
        companyRoleId: showRoleName === false ? selectedRole?.id : null,
        selectAllLocations:
          manageLocation?.label === "All Locations" ? true : false,
        sendMail: isModalEmailsSent,
        allLocationAccess:
          manageLocation?.label === "All Locations" ? true : false,
        locationIds: locationIds,
        groupIds,
        newRole: showRoleName ? true : false,
        name: showRoleName
          ? roleName?.replaceAll(" ", "-").toLowerCase()
          : selectedRole?.name,
        permissions: allPermissions?.length > 0 ? allPermissions : [],
      });
      if (res.status === 201) {
        setSubmitUserLoading(false);
        if (totalEmailsArr?.length === 0 && res?.data?.data?.length > 0) {
          setInviteLink(res?.data?.data[0]?.inviteCode);
          handleOpenSharableLinkModel();
        } else {
          handleOpenAddedEmailModel();
          setAddedEmails(res?.data?.data);
          // onCancelAddUser();
        }

        //  toast.success(res?.data?.message);
      }
    } catch (error) {
      setSubmitUserLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleChangeChecked = (e) => {
    setIsModalEmailsSent(e.target.checked);
  };

  const handleValidateEmails = async (fromModel) => {
    setValidEmailLoading(true);

    try {
      const res = await api.post(`user/validateEmails`, {
        emails: fromModel ? selectedItems : totalEmailsArr,
      });
      if (res?.status === 200) {
        setValidateEmailsError(res?.data?.data);
        setValidEmailLoading(false);
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      setValidEmailLoading(false);
      setValidateEmailsError([]);
    }
  };

  const handleValidateEmailsAfterAdding = async (emailsArr) => {
    setValidEmailLoading(true);

    try {
      const res = await api.post(`user/validateEmails`, {
        emails: emailsArr,
      });
      if (res?.status === 200) {
        setValidateEmailsError(res?.data?.data);
        setValidEmailLoading(false);
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      setValidEmailLoading(false);
      setValidateEmailsError([]);
    }
  };

  const handleremoveEmails = () => {
    const stringArray = addNewUserEmials
      .split(",\n")
      .map((email) => email.trim());
    let arr = [];
    validateEmailsError?.map((item) => arr.push(item?.email));
    const set3 = new Set(totalEmailsArr);
    const set1 = new Set(arr);

    if (arr?.length > 0) {
      const uniqueEmailsSet = new Set(arr);
      const uniqueEmailsArray = Array.from(uniqueEmailsSet);

      const uniqueEmails = totalEmailsArr.filter(
        (email) => !uniqueEmailsArray.includes(email)
      );
      setTotalEmailsArr(uniqueEmails);

      const uniqueEmailsTwo = validEmailsArray.filter(
        (email) => !uniqueEmailsArray.includes(email)
      );
      setValidEmailsArray(uniqueEmailsTwo);

      const uniqueEmailsThree = selectedItems.filter(
        (email) => !uniqueEmailsArray.includes(email)
      );
      setSelectedItems(uniqueEmailsThree);

      const filteredStringArray = stringArray.filter(
        (email) => email && !arr.includes(email)
      );
      const resultString = filteredStringArray.join(",\n");
      setAddNewUserEmails(resultString);

      setTimeout(() => {
        setValidateEmailsError([]);
      }, 100);
    }
  };

  return (
    <>
      {openEmailModal && (
        <FetchEmailsModel
          title={t("fetched_emails")}
          subTitle={t("fetch_emails_subheading")}
          open={openEmailModal}
          handleCloseNewRequestModel={handleCloseEmailsModal}
          handleCancelEmailsModal={handleCancelEmailsModal}
          dataArray={dataArray}
          totalModalEmailsArr={totalModalEmailsArr}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectAllChecked={selectAllCheckedModal}
          setSelectAllChecked={setSelectAllCheckedModal}
          handleSubmitOk={handleSubmitOk}
          isLoading={fileLoading}
          isModalEmailsSent={isModalEmailsSent}
          setIsModalEmailsSent={setIsModalEmailsSent}
        />
      )}

      {openAddedEmailModal && (
        <AddedEmailModel
          title={t("user_emails")}
          subTitle={t("user_email_subHead")}
          open={openAddedEmailModal}
          handleCloseNewRequestModel={handleCloseAddedEmailModel}
          addedEmails={addedEmails}
          handleSubmitOk={handleOkAddedEmails}
        />
      )}
      {openShareableLinkModel && (
        <ShareableLinkModel
          title={t("Shareable_Link")}
          open={openShareableLinkModel}
          handleCloseNewRequestModel={handleCloseSharableLinkModel}
          inviteLink={inviteLink}
          subTitle={t("shareable_link_subhead")}
        />
      )}
      <Title
        title={t("add_new_user")}
        showButton={true}
        navigateToAccountOverView={true}
        showDivider={true}
      />

      {locationLoading ? (
        <Loader />
      ) : (
        <>
          {allLocations?.length > 0 ? (
            <>
              <Grid container mt={2}>
                <Grid item xs={12} lg={12}>
                  <ThemeProvider theme={theme}>
                    <Grid>
                      <Grid className={classes.addUserGrid}>
                        <Typography
                          className={classes.addUserFieldLable}
                          mb={2}
                        >
                          {t("Email_Address")}
                        </Typography>

                        <Box mt={3}>
                          <TextField
                            id="outlined-basic"
                            multiline
                            rows={5.8}
                            maxRows={10}
                            ref={textArea}
                            variant="outlined"
                            placeholder={t("Enter_email_here")}
                            sx={{ marginBottom: "12px" }}
                            value={addNewUserEmials}
                            fullWidth
                            // error={emailsError}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                handleAddNewUserEmailsChange(
                                  event.target.value + ","
                                );
                              }
                            }}
                            //  onBlur={() => handleValidateEmails(false)}
                            onChange={(e) =>
                              handleAddNewUserEmailsChange(e.target.value)
                            }
                            // helperText={
                            //   emailsError ? "This field is required" : ""
                            // }
                          />
                        </Box>
                        <Box className={classes.sendEmailBox}>
                          <Checkbox
                            checked={isModalEmailsSent}
                            onChange={handleChangeChecked}
                            className={classes.sendEmailCheckBox}
                          />
                          <Typography className={classes.sendEmailTypo}>
                            {t("modal_email_sent_msg")}
                          </Typography>
                        </Box>
                        {/* <Box
                  mb={3}
                >{`${stepperData?.validEmailCounter} valid emails entered`}</Box> */}
                        <Box className={classes.reviewsubHeadBox}>
                          {totalEmailsArr?.length > 5000 ? (
                            <Typography
                              className={classes.reviewsubHeadTextError}
                            >
                              {t("max_upload_text")}
                            </Typography>
                          ) : (
                            <Typography className={classes.reviewsubHeadText}>
                              {validEmailLoading
                                ? t("checking_email")
                                : (isModalEmailsSent
                                    ? t("send_email_textTwo")
                                    : t("send_email_text")) +
                                  " " +
                                  totalEmailsArr?.length +
                                  " " +
                                  t("email_addresses")}
                            </Typography>
                          )}
                        </Box>
                        {validateEmailsError?.length > 0 && (
                          <Grid container className={classes.mainContainerUser}>
                            <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                              <ErrorOutlineIcon
                                className={classes.errorIconUser}
                              />
                            </Grid>
                            <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Typography className={classes.errorTextUser}>
                                    {t("email_exist_error")}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  {validateEmailsError?.map((item, index) => (
                                    <Typography
                                      key={index}
                                      className={classes.errorTextUser}
                                    >
                                      {"•" + " " + item?.email}
                                    </Typography>
                                  ))}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                sx={{ textAlign: "end" }}
                              >
                                <Button onClick={() => handleremoveEmails()}>
                                  <Typography
                                    className={classes.removeEmailTypography}
                                  >
                                    {t("remove_emails")}
                                  </Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        <Box>
                          <input
                            type="file"
                            id="file-email"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={handleFileUpload}
                            ref={fileInputRef}
                            style={{ display: "none" }}
                          />

                          <Box mt={3} ml={-3}>
                            <CommonButton
                              onSubmit={handleButtonClick}
                              label={t("Browse_File")}
                            />
                          </Box>

                          <Box>
                            {selectedFile ? (
                              <Box className={classes.fileBoxStyle}>
                                <Typography
                                  onClick={handleReopenModal}
                                  className={classes.selectedFileTypo}
                                >
                                  {selectedFile}
                                </Typography>
                                <IconButton
                                  className={classes.removeFileButton}
                                  onClick={() => handleRemoveFile()}
                                >
                                  <DeleteIcon
                                    fontSize="small"
                                    className={classes.deleteIcon}
                                  />
                                </IconButton>
                              </Box>
                            ) : (
                              <p>{t("")}</p>
                            )}
                          </Box>
                        </Box>

                        <Box mt={6}>
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              className={classes.addUserFieldLable}
                              mb={2}
                            >
                              {t("location_access")}
                            </Typography>
                            <BootstrapTooltip
                              title={
                                <h1 style={{ fontSize: "12px" }}>
                                  {t("location_access_tooltip")}
                                </h1>
                              }
                            >
                              <ErrorOutlineIcon
                                // fontSize="small"
                                className={classes.quotionIcon}
                              />
                            </BootstrapTooltip>
                          </Box>

                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={manangeLocations}
                            getOptionLabel={(option) => option?.displayName}
                            fullWidth
                            onChange={(e, value) => {
                              setManageLocation(value);

                              if (value?.label === "All Locations") {
                                let locationIds = [];
                                if (allLocations?.length > 0) {
                                  allLocations?.map((item) => {
                                    locationIds?.push(item);
                                  });
                                }
                                setSelectedGroups([]);
                                setSelectAllGroups([]);
                                setLocationValue(locationIds);
                                setLocationError(false);
                              } /*  else if (value?.label === "Groups") {
                                let locationIds = [];
                                if (allLocations?.length > 0) {
                                  allGroups?.rows?.map((group) => {
                                    group?.groups?.map((groupItem) => {
                                      allLocations?.map((location) => {
                                        if (
                                          groupItem?.locationId === location?.id
                                        ) {
                                          locationIds?.push(location);
                                        }
                                      });
                                    });
                                  });
                                }
                                const uniqueData =
                                  removeDuplicates(locationIds);
                                setLocationValue(uniqueData);
                                setLocationError(false);
                              } */ else {
                                setLocationValue([]);
                                setLocationError(false);
                                setSelectedGroups([]);
                                setSelectAllGroups(false);
                              }
                            }}
                            value={manageLocation}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t("Locations")}
                              />
                            )}
                          />
                        </Box>
                        {manageLocation?.label === "Groups" && (
                          <Box mt={6}>
                            <Typography
                              className={classes.addUserFieldLable}
                              mb={2}
                            >
                              {t("group_access")}
                            </Typography>

                            <Autocomplete
                              multiple
                              disablePortal
                              disableCloseOnSelect
                              id="combo-box-demo"
                              options={
                                allGroups !== null &&
                                allGroups?.rows?.length > 0
                                  ? allGroups?.rows
                                  : []
                              }
                              getOptionLabel={(option) =>
                                option?.name ? option?.name : ""
                              }
                              className="Autocomplete-field"
                              value={selectedGroups ? selectedGroups : []}
                              onChange={(e, value) => {
                                if (value.find((option) => option.id === 0)) {
                                  if (
                                    selectedGroups?.length ==
                                    allGroups?.rows?.length
                                  ) {
                                    setSelectedGroups([]);
                                    setSelectAllGroups(false);
                                  } else {
                                    setSelectAllGroups(true);
                                    handleChangeGroups(allGroups?.rows);
                                  }
                                } else {
                                  handleChangeGroups(value);
                                  if (
                                    value?.length === allGroups?.rows?.length
                                  ) {
                                    setSelectAllGroups(true);
                                  } else {
                                    setSelectAllGroups(false);
                                  }
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                if (allGroups?.rows?.length > 0) {
                                  return [
                                    { id: 0, name: "Select All" },
                                    ...filtered,
                                  ];
                                } else {
                                  return [];
                                }
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selectAllGroups || selected}
                                    id="select-all-checkbox"
                                  />

                                  <Typography>{option?.name}</Typography>
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  placeholder={t("Select_group")}
                                  {...params}
                                  required
                                  name="language"
                                />
                              )}
                            ></Autocomplete>
                          </Box>
                        )}

                        {(manageLocation?.label === "Specific Locations" ||
                          manageLocation?.label === "Groups") && (
                          <Box mt={6}>
                            <Typography
                              className={classes.addUserFieldLable}
                              mb={2}
                            >
                              {t("Select_Locations")}
                            </Typography>

                            {allLocations?.length > 0 && (
                              <Autocomplete
                                multiple
                                error={locationError}
                                disablePortal
                                id="combo-box-demo"
                                disableCloseOnSelect
                                options={
                                  allLocations?.length > 0 ? allLocations : []
                                }
                                value={locationValue}
                                onChange={(event, value) => {
                                  // Check if 'All' option is clicked
                                  if (value?.length > 0) {
                                    setLocationError(false);
                                  } else {
                                    setLocationError(true);
                                  }

                                  if (value.find((option) => option.id === 0)) {
                                    // Check if all options are selected
                                    if (
                                      allLocations?.length ===
                                      locationValue?.length
                                    ) {
                                      setLocationValue([]);
                                      setSelectAll(false);
                                    } else {
                                      setSelectAll(true);
                                      handleChangeGroupsForLocations(
                                        allLocations
                                      );
                                      setLocationValue(allLocations);
                                      //  setLocationRequired(false);
                                    }
                                  } else {
                                    setLocationValue(value);
                                    handleChangeGroupsForLocations(value);
                                    if (
                                      value?.length === allLocations?.length
                                    ) {
                                      setSelectAll(true);
                                    } else {
                                      setSelectAll(false);
                                    }
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  if (allLocations?.length > 0) {
                                    return [
                                      { id: 0, internalName: "Select All" },
                                      ...filtered,
                                    ];
                                  } else {
                                    return [];
                                  }
                                }}
                                defaultValue={locationValue}
                                getOptionLabel={(option) =>
                                  option?.internalName
                                    ? option?.internalName +
                                      " " +
                                      "(" +
                                      (option?.addressLine1 !== "" &&
                                      option?.addressLine1 !== null &&
                                      option?.addressLine1 !== undefined
                                        ? option?.addressLine1 + "," + " "
                                        : "") +
                                      (option?.city ? option?.city : "") +
                                      ")"
                                    : ""
                                }
                                sx={{ minWidth: 300 }}
                                // value={locationId ? locationId : []}
                                // onChange={(e, value) => {
                                //   setLocationRequired(false);

                                //   setSelectedLocation(value);
                                // }}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      id="select-all-checkbox"
                                      checked={selectAll || selected}
                                      // checked={selected}
                                    />
                                    {`${option?.internalName} ${
                                      option?.addressLine1 !== "" &&
                                      option?.addressLine1 !== null &&
                                      option?.addressLine1 !== undefined
                                        ? "(" + option?.addressLine1 + "," + " "
                                        : ""
                                    } ${
                                      option?.city ? option?.city + ")" : ""
                                    }`}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    name="language"
                                    {...params}
                                    error={locationError}
                                    placeholder={t("Select_Location")}
                                    //  defaultValue={{ selectedLocation }}
                                    helperText={
                                      locationError ? t("location_req") : ""
                                    }
                                  />
                                )}
                              ></Autocomplete>
                            )}
                          </Box>
                        )}

                        <Box mt={6}>
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              className={classes.addUserFieldLable}
                              mb={2}
                            >
                              {t("date_format")}
                            </Typography>
                            <BootstrapTooltip
                              title={
                                <h1 style={{ fontSize: "12px" }}>
                                  {t("date_format_tooltip")}
                                </h1>
                              }
                            >
                              <ErrorOutlineIcon
                                className={classes.quotionIcon}
                              />
                            </BootstrapTooltip>
                          </Box>

                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={dateFormats}
                            fullWidth
                            onChange={(e, value) => {
                              setDateFormat(value);
                            }}
                            value={dateFormat}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t("date_format")}
                              />
                            )}
                          />
                        </Box>

                        <Box mt={6}>
                          <Box className={classes.addUserBox}>
                            <Typography
                              className={classes.addUserFieldLable}
                              mb={2}
                            >
                              {t("select_role")}
                            </Typography>
                            <Box mb={2}>
                              <Button autoFocus onClick={handleCreateNewRole}>
                                <AddIcon fontSize="medium" />
                                <Typography className={classes.createRole}>
                                  {t("create_new_role")}
                                </Typography>
                              </Button>
                            </Box>
                          </Box>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={allRoles !== null ? allRoles?.rows : []}
                            getOptionLabel={(option) =>
                              (
                                option?.name.charAt(0).toUpperCase() +
                                option?.name.slice(1)
                              ).replaceAll("-", " ")
                            }
                            fullWidth
                            value={selectedRole}
                            onChange={handleChangeSelectedRole}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t("select_role")}
                                error={selectedRoleError}
                                helperText={
                                  selectedRoleError ? t("role_req") : ""
                                }
                              />
                            )}
                          />
                        </Box>
                        {showRoleName && (
                          <Box mt={6}>
                            <Typography
                              className={classes.addUserFieldLable}
                              mb={2}
                            >
                              {t("role_name")}
                            </Typography>
                            <TextField
                              fullWidth
                              placeholder={t("role_placeHolder")}
                              value={roleName}
                              onChange={handleChangeRoleName}
                              error={roleNameError || sameNameError}
                              helperText={
                                roleNameError
                                  ? t("roleName_required")
                                  : sameNameError
                                  ? t("roleName_can't_same")
                                  : ""
                              }
                            />
                          </Box>
                        )}
                        {showFeatures && (
                          <AddNewUsersFeatures
                            selectedRole={selectedRole}
                            duplicateRole={duplicateRole}
                            setDuplicateRole={setDuplicateRole}
                            setSelectedRole={setSelectedRole}
                            setPermissionsArr={setPermissionsArr}
                            permissionsArr={permissionsArr}
                            companyFeatures={companyFeatures}
                            singleRole={singleRole}
                            showRoleName={showRoleName}
                            setShowRoleName={setShowRoleName}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </Grid>
              </Grid>
              <Grid container className={classes.addUserButtonContainer}>
                <Box sx={{ marginRight: "15px" }}>
                  <CommonButton
                    displayWhite="true"
                    onSubmit={onCancelAddUser}
                    label={t("Cancel")}
                  />
                </Box>

                <CommonButton
                  label={
                    totalEmailsArr?.length > 0
                      ? t("Submit")
                      : t("get_shareable_link")
                  }
                  isLoading={submitUserLoading}
                  disabled={
                    /*  totalEmailsArr?.length <= 0
                      ? true
                      : emailsError
                      ? true
                      : */

                    submitUserLoading
                      ? true
                      : validateEmailsError?.length > 0
                      ? true
                      : disableButton
                      ? true
                      : selectedRole === null && roleName === ""
                      ? true
                      : duplicateRole && roleName === ""
                      ? true
                      : roleNameError
                      ? true
                      : selectedRoleError
                      ? true
                      : manageLocation?.label === "Specific Locations" &&
                        locationError
                      ? true
                      : manageLocation?.label === "Specific Locations" &&
                        locationValue.length === 0
                      ? true
                      : manageLocation?.label === "Groups" &&
                        locationValue.length === 0
                      ? true
                      : false
                  }
                  onSubmit={handleSubmitInvite}
                  // loading={isLoading}
                />
              </Grid>
            </>
          ) : allLocations === null ? (
            <Box className={classes.locationbox}>
              <NoLocationFound
                text={t("No_Location_Added")}
                subtext={t("add_location_text")}
              />
            </Box>
          ) : null}
        </>
      )}
    </>
  );
}

export default AddNewUser;
