import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Box,
  TextField,
  Card,
  Checkbox,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Dialog } from "@material-ui/core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../../.././../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../.././../components/Loaders/Loader";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
// import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import ConfirmModal from "../../../../../.././../components/Models/ConfirmationModal";
import ConfirmModalTwo from "../../../../../../../components/Models/ConfirmModalLodging";
import Delete from "../../../../../../../components/Models/DeleteModal/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CustomFields from "./CustomFields";
import Description from "./Description";
import Attributes from "./Attributes";
import CoverImages from "./CoverImages";
import { languagesData } from "../../../../../../../assets/defaultLanguage";

const LocalPages = ({
  onCancel,
  data,
  locationDetailsData,
  location,
  locationCategory,
  locationFacebookCategory,
  getLocationDetails,
  getLocationDetailsListing,
  facebookConnect,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setLocationDetailsData,
  indexValue,
  setSelectedTab,
  selectedTab,
  setIndexValue,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [localPagesData, setLocalPagesData] = useState({
    clickAndConnect: "",
    deliveroo: "",
    justEat: "",
    uberEats: "",
    menuLink: "",
    slug: "",
    pedidosYa: "",
    rappi: "",
    grubHub: "",
    doorDash: "",
    wolt: "",
    iFood: "",
    eFood: "",
    displayName: "",
  });
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
  const classes = useStyles();

  const theme = useTheme();
  const containerRef = useRef(0);
  const [cancelState, setCancelState] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState(0);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [otherValue, setOtherValue] = useState(null);
  const [menuLinkRequired, setMenuLinkRequred] = useState(false);
  const [clickAndConnectRequired, setClickAndConnectRequired] = useState(false);
  const [deliverooRequired, setDeliverooRequired] = useState(false);
  const [justEatRequired, setJustEatRequired] = useState(false);
  const [uberEatsRequired, setUberEatsRequired] = useState(false);
  const [menuLinkIsValid, setMenuLinkIsValid] = useState();
  const [clickAndConnectIsValid, setClickAndConnectIsValid] = useState();
  const [deliverooIsValid, setDeliverooIsValid] = useState();
  const [justEatIsValid, setJustEatIsValid] = useState();
  const [uberEatsIsValid, setUberEatsIsValid] = useState();
  const [slugIsValid, setSlugIsValid] = useState();
  const [slugRequired, setSlugRequired] = useState(false);
  const [descriptionSetArray, setDescriptionSetArray] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const userLanguage = localStorage.getItem("i18nextLng");
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [allAttributes, setAllAttributes] = useState([]);
  const [languagesDataArray, setLanguagesDataArray] = useState([]);
  const [allData, setAllData] = useState([]);
  const [disableAddDescription, setDisableAddDescription] = useState(false);
  const [imagesShowData, setImagesShowData] = useState([]);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [uploadImgError, setUploadImgError] = useState(false);
  const [imagesUploadData, setImagesUploadData] = useState([]);
  const [alreadyAddedImages, setAlreadyAddedImages] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteState, setDeleteState] = useState(null);

  useEffect(() => {
    if (value === 3) {
      let objjj = [];
      let dummyObj = [];
      objjj = JSON.stringify(locationDetailsData?.result?.coverImages);
      dummyObj = JSON.parse(objjj);
      setImagesUploadData(dummyObj);
      setImagesShowData(dummyObj);
      setAlreadyAddedImages(dummyObj?.length);
    }
  }, [value, locationDetailsData]);

  useEffect(() => {
    if (value === 2 && descriptionSetArray?.length > 0) {
      const hasEmptyName = descriptionSetArray.some(
        (item) => item.language === "" || item?.description === ""
      );
      setDisableAddDescription(hasEmptyName);
    }
  }, [descriptionSetArray, value]);
  useEffect(() => {
    if (value === 1) {
      const hasEmptyName = selectedAttributes.some(
        (item) => item.displayNames === ""
      );
      setDisableButton(hasEmptyName);
    }
  }, [selectedAttributes, value]);

  useEffect(() => {
    if (value === 2) {
      const results = languagesData?.filter(
        ({ value: id1 }) =>
          !descriptionSetArray.some(({ language: id2 }) => id2 === id1)
      );
      setLanguagesDataArray(results);
    }
  }, [descriptionSetArray, value]);

  useEffect(() => {
    if (value === 1) {
      getLocalPagesAttributes();
    }
  }, [value]);
  useEffect(() => {
    setLanguagesDataArray(languagesData);
  }, []);
  useEffect(() => {
    // Set scroll position to the top of the container
    if (isLoading === false) {
      handleScroll();
    }
  }, [value, isLoading]);
  useEffect(() => {
    // Set scroll position to the top of the container
    if (value === 2) {
      handleScroll();
    }
  }, [value]);

  useEffect(async () => {
    if (locationDetailsData !== null) {
      setLocalPagesData({
        clickAndConnect:
          locationDetailsData?.result?.deliveryOptions?.clickAndConnect,
        deliveroo: locationDetailsData?.result?.deliveryOptions?.deliveroo,
        justEat: locationDetailsData?.result?.deliveryOptions?.justEat,
        uberEats: locationDetailsData?.result?.deliveryOptions?.uberEats,
        menuLink: locationDetailsData?.result?.menuLink,
        displayName: locationDetailsData?.result?.displayName,
        slug: locationDetailsData?.result?.slug,
        pedidosYa: locationDetailsData?.result?.deliveryOptions?.pedidosYa,
        rappi: locationDetailsData?.result?.deliveryOptions?.rappi,
        grubHub: locationDetailsData?.result?.deliveryOptions?.grubHub,
        doorDash: locationDetailsData?.result?.deliveryOptions?.doorDash,
        wolt: locationDetailsData?.result?.deliveryOptions?.wolt,
        iFood: locationDetailsData?.result?.deliveryOptions?.iFood,
        eFood: locationDetailsData?.result?.deliveryOptions?.eFood,
      });
      if (locationDetailsData?.result?.descriptions?.length > 0) {
        let objjj = [];
        let dummyObj = [];
        objjj = JSON.stringify(locationDetailsData?.result?.descriptions);
        dummyObj = JSON.parse(objjj);
        let arr = [];
        dummyObj?.map((item) => {
          item["languageError"] = false;
          item["descriptionError"] = false;

          arr.push(item);
        });

        await setDescriptionSetArray([...arr]);
        if (deleteState === null) {
          await handleScroll();
        }
      } /* else {
      let tempObj = {
        language: "",
        description: "",
        newAdded: "",
        languageError: false,
        descriptionError: false,
      };
      setDescriptionSetArray([tempObj]);
    } */
    }
  }, [value, isLoading, locationDetailsData]);

  //to validate that custom url is valid or not //
  const urlRegex =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  function validateUrl(url) {
    return urlRegex.test(url);
  }
  const handleScroll = () => {
    if (containerRef.current !== null) {
      containerRef.current.scrollTop = 0;
    }
  };

  /* useEffect(() => {
    if (localPagesData?.menuLink?.length > 0) {
      const isValidUrl = validateUrl(localPagesData?.menuLink);

      if (isValidUrl) {
        setMenuLinkIsValid(true);
      } else {
        setMenuLinkIsValid(false);
      }
    }
  }, [localPagesData?.menuLink]);
  useEffect(() => {
    if (localPagesData?.clickAndConnect?.length > 0) {
      const isValidUrl = validateUrl(localPagesData?.clickAndConnect);

      if (isValidUrl) {
        setClickAndConnectIsValid(true);
      } else {
        setClickAndConnectIsValid(false);
      }
    }
  }, [localPagesData?.clickAndConnect]);
  useEffect(() => {
    if (localPagesData?.deliveroo?.length > 0) {
      const isValidUrl = validateUrl(localPagesData?.deliveroo);

      if (isValidUrl) {
        setDeliverooIsValid(true);
      } else {
        setDeliverooIsValid(false);
      }
    }
  }, [localPagesData?.deliveroo]);
  useEffect(() => {
    if (localPagesData?.justEat?.length > 0) {
      const isValidUrl = validateUrl(localPagesData?.justEat);

      if (isValidUrl) {
        setJustEatIsValid(true);
      } else {
        setJustEatIsValid(false);
      }
    }
  }, [localPagesData?.justEat]);
  useEffect(() => {
    if (localPagesData?.uberEats?.length > 0) {
      const isValidUrl = validateUrl(localPagesData?.uberEats);

      if (isValidUrl) {
        setUberEatsIsValid(true);
      } else {
        setUberEatsIsValid(false);
      }
    }
  }, [localPagesData?.uberEats]); */

  const handleDeleteImage = (index) => {
    let deleteCount = alreadyAddedImages;
    if (deleteCount > 0) {
      setAlreadyAddedImages(deleteCount - 1);
    }
    const deleteImage = imagesShowData.filter((item, indx) => {
      return indx !== index;
    });
    setImagesShowData(deleteImage);
    setTouchedValue(true);
  };

  const getLocalPagesAttributes = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `localPages/${location?.id}/attributes?language=${userLanguage}`
      );
      if (res.status === 200) {
        if (res?.data?.data?.length > 0) {
          const filterSelectedAttributes = res?.data?.data?.filter(
            (item) => item?.value !== null
          );
          setSelectedAttributes(filterSelectedAttributes);

          const filterNonSelectedAttributes = res?.data?.data?.filter(
            (item) => item?.value === null
          );
          setAllAttributes(filterNonSelectedAttributes);
          setAllData(res?.data?.data);
        }

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      getLocationDetails(location);
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };

  const handleUpdateLocalPagesData = async () => {
    /*   if (
      localPagesData?.menuLink==="" ||
      localPagesData?.menuLink===undefined ||
      localPagesData?.menuLink===null
    ) {
      setMenuLinkRequred(true);
    }
    if (
      localPagesData?.clickAndConnect==="" ||
      localPagesData?.clickAndConnect===undefined ||
      localPagesData?.clickAndConnect===null
    ) {
      setClickAndConnectRequired(true);
    }
    if (
      localPagesData?.deliveroo==="" ||
      localPagesData?.deliveroo===undefined ||
      localPagesData?.deliveroo===null
    ) {
      setDeliverooRequired(true);
    }
    if (
      localPagesData?.justEat==="" ||
      localPagesData?.justEat===undefined ||
      localPagesData?.justEat===null
    ) {
      setJustEatRequired(true);
    }
    if (
      localPagesData?.uberEats==="" ||
      localPagesData?.uberEats===undefined ||
      localPagesData?.uberEats===null
    ) {
      setUberEatsRequired(true);
    } */
    if (
      localPagesData?.slug === "" ||
      localPagesData?.slug === undefined ||
      localPagesData?.slug === null
    ) {
      setSlugRequired(true);
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    } else {
      if (
        localPagesData?.slug !== "" &&
        localPagesData?.slug !== undefined &&
        localPagesData?.slug !== null
      ) {
        try {
          setIsLoading(true);
          setDisplayConfirmationBox(false);
          const res = await api.patch(
            `/locationListing/update-local-pages/${location?.id}`,
            {
              deliveryOptions: {
                clickAndConnect: localPagesData?.clickAndConnect,
                deliveroo: localPagesData?.deliveroo,
                justEat: localPagesData?.justEat,
                uberEats: localPagesData?.uberEats,
                pedidosYa: localPagesData?.pedidosYa,
                rappi: localPagesData?.rappi,
                grubHub: localPagesData?.grubHub,
                doorDash: localPagesData?.doorDash,
                wolt: localPagesData?.wolt,
                iFood: localPagesData?.iFood,
                eFood: localPagesData?.eFood,
              },
              displayName: localPagesData?.displayName,
              menuLink: localPagesData?.menuLink,
              slug: localPagesData?.slug,
            }
          );
          if (indexValue !== null) {
            let selectedValue = indexValue;

            setSelectedTab(selectedValue);
          } else {
            handleCloseBox();
          }
          if (cancelState === true) {
            onCancel();
          }
          if (res.status === 200) {
            setIsLoading(false);
            setTouchedValue(false);
            toast.success("Updated Successfully");

            // onCancel();
            // getLocationDetails(location);
            getLocationDetailsListing(location);
            // setLocationDetailsData(res?.data?.data);
          }
        } catch (error) {
          toast.error(error?.response?.data?.message);
          setIsLoading(false);
          handleClose();
          handleCloseBox();
        }
      }
    }
  };
  const handleImagesShow = (images) => {
    if (images?.length > 0) {
      images.map((item) => {
        imagesShowData.push(item);
      });
    }
    setImagesShowData([...imagesShowData]);
    setAlreadyAddedImages(imagesShowData?.length);
  };

  const handleUpdateDescription = async () => {
    if (descriptionSetArray?.length > 0) {
      let arr = [];
      let errorFound = false;
      let tempArray = [...descriptionSetArray];
      tempArray?.forEach((item) => {
        if (item?.description === "") {
          item["descriptionError"] = true;
          errorFound = true;
        }
        if (item?.language === "") {
          item["languageError"] = true;
          errorFound = true;
        }
        if (item?.language.includes("_")) {
          item["language"] = item?.language.replace("_", "-");
        }
      });

      setDescriptionSetArray(tempArray);
      if (errorFound === false) {
        if (descriptionSetArray?.length > 0) {
          descriptionSetArray?.forEach((item) => {
            delete item["newAdded"];
            delete item["languageError"];
            delete item["descriptionError"];
            arr?.push(item);
          });
        }
        try {
          setIsLoading(true);
          const res = await api.put(`localPages/${location?.id}/description`, {
            descriptions: arr,
          });
          setDisplayConfirmationBox(false);

          if (indexValue !== null) {
            let selectedValue = indexValue;

            setSelectedTab(selectedValue);
          } else {
            handleCloseBox();
          }
          if (cancelState === true) {
            onCancel();
          }
          if (res.status === 200) {
            getLocationDetailsListing(location);

            setTouchedValue(false);
            toast.success("Updated Successfully");
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
            // onCancel();
            // getLocationDetails(location);

            // setLocationDetailsData(res?.data?.data);
          }
        } catch (error) {
          toast.error(error?.response?.data?.message || "Something went wrong");
          handleCloseDeleteModel();
          setDeleteId(null);
          setIsLoading(false);
          handleCloseBox();
          handleClose();
        }
      } else {
        setDisplayConfirmationBox(false);
        setIndexValue(null);
      }
    }
  };

  const handleUpdateAttributes = async () => {
    if (disableButton === false) {
      setIsLoading(true);
      let arr = [];
      if (selectedAttributes?.length > 0) {
        selectedAttributes?.map((item) => {
          let obj = {
            attributeId: item?._id,
            value:
              item?.value === "false"
                ? false
                : item?.value === "true"
                ? true
                : item?.value,
          };
          arr.push(obj);
        });
      }
      if (allAttributes?.length > 0) {
        allAttributes?.map((item) => {
          let obj = {
            attributeId: item?._id,
            value:
              item?.value === "false"
                ? false
                : item?.value === "true"
                ? true
                : item?.value,
          };
          arr.push(obj);
        });
      }

      try {
        const res = await api.put(`localPages/${location?.id}/attributes`, {
          attributes: arr,
        });
        setDisplayConfirmationBox(false);
        if (indexValue !== null) {
          let selectedValue = indexValue;

          setSelectedTab(selectedValue);
        } else {
          handleCloseBox();
        }
        if (cancelState === true) {
          onCancel();
        }
        if (res.status === 200) {
          setIsLoading(false);
          getLocalPagesAttributes();
          setTouchedValue(false);
          toast.success("Updated Successfully");
          // onCancel();
          // getLocationDetails(location);
          getLocationDetailsListing(location);
          // setLocationDetailsData(res?.data?.data);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        handleCloseDeleteModel();
        handleClose();
        handleCloseBox();
        setDeleteId(null);
        setIsLoading(false);
      }
    } else {
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    }
  };

  const handleDeleteDescriptions = async () => {
    setDeleteState("false");
    try {
      setDeleteLoading(true);
      const res = await api.delete(`localPages/${deleteId}`);

      /*  if (indexValue !==null) {
        let selectedValue = indexValue;
      
        setSelectedTab(selectedValue);
      }
      if (cancelState===true) {
        onCancel();
      } */
      if (res.status === 200) {
        setDeleteLoading(false);
        //  setTouchedValue(false);
        toast.success(res?.data?.message);
        const filteredPosts = descriptionSetArray?.filter((data) => {
          return data?._id !== deleteId;
        });
        setDescriptionSetArray(filteredPosts);
        handleCloseDeleteModel();
        setDeleteState("true");
        /*   if (filteredPosts?.length===0) {
          let tempObj = {
            language: "",
            description: "",
            newAdded: "",
            languageError: false,
            descriptionError: false,
          };
          setDescriptionSetArray([tempObj]);
        } else {
        
        } */

        setDeleteId(null);

        // onCancel();
        // getLocationDetails(location);
        getLocationDetailsListing(location);
        // setLocationDetailsData(res?.data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      handleCloseDeleteModel();
      setDeleteId(null);

      setDeleteLoading(false);
    }
  };

  const handleUpdateCoverImages = async () => {
    // if (imagesShowData?.length===0) {
    //   setUploadImgError(true);
    // }

    // if (imagesShowData?.length > 0) {
    try {
      setIsLoading(true);
      setDisplayConfirmationBox(false);
      const res = await api.patch(
        `/locationListing/update-cover-images/${location?.id}`,
        {
          coverImages: imagesShowData,
        }
      );
      if (indexValue !== null) {
        let selectedValue = indexValue;

        setSelectedTab(selectedValue);
      }
      if (cancelState === true) {
        onCancel();
      }

      getLocationDetailsListing(location);
      if (res.status === 200) {
        setIsLoading(false);
        setTouchedValue(false);
        toast.success("Updated Successfully");
        handleCloseDeleteModel();
        handleCloseBox();
        // onCancel();
        // getLocationDetails(location);

        // setLocationDetailsData(res?.data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
      handleCloseDeleteModel();
      handleCloseBox();
    }
    // }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setSlugRequired(false);
    if (touchedValue === true) {
      setOpenConfirmBox(true);
      setOtherValue(newValue);
    } else {
      setValue(newValue);
      setOtherValue(null);
    }
  };

  const handleAddNewDescription = () => {
    setTouchedValue(true);
    let tempObj = {
      language: "",
      description: "",
      newAdded: true,
      languageError: false,
      descriptionError: false,
    };
    descriptionSetArray.push(tempObj);
    setDescriptionSetArray([...descriptionSetArray]);
  };
  const handleDeleteDescription = (index) => {
    // setTouchedValue(true);
    const filteredPosts = descriptionSetArray?.filter((data, indx) => {
      return indx !== index;
    });

    setDescriptionSetArray(filteredPosts);
  };

  const handleChangeLanguage = (value, index) => {
    setTouchedValue(true);
    let tempArray = [...descriptionSetArray];
    if (value !== null && value !== undefined && value !== "") {
      tempArray[index].languageError = false;
    } else {
      tempArray[index].languageError = true;
    }

    tempArray[index].language = value;
    setDescriptionSetArray(tempArray);
  };
  const handleChangeDescription = (value, index) => {
    setTouchedValue(true);
    let tempArray = [...descriptionSetArray];
    if (value !== null && value !== undefined && value !== "") {
      tempArray[index].descriptionError = false;
    } else {
      tempArray[index].descriptionError = true;
    }
    tempArray[index].description = value;
    setDescriptionSetArray(tempArray);
  };

  const handleImportDescription = (index) => {
    setTouchedValue(true);
    let tempArray = [...descriptionSetArray];
    if (locationDetailsData?.result?.google?.profile?.description !== null) {
      tempArray[index].description =
        locationDetailsData?.result?.google?.profile?.description;
      tempArray[index].descriptionError = false;
    }
    setDescriptionSetArray(tempArray);
  };
  const handleCloseBox = () => {
    setOpenConfirmBox(false);
    if (otherValue !== null) {
      setValue(otherValue);
      setTouchedValue(false);
    }
  };

  const handleOpenDeleteModel = (descriptionId) => {
    setOpenDeleteModel(true);
    setDeleteId(descriptionId);
  };
  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteId(null);
  };

  const handleAddAttribute = (index) => {
    setTouchedValue(true);
    let tempArray = [...selectedAttributes];
    tempArray[index].value = "true";
    // setAttributes(tempArray);
  };

  const handleRemoveAttribute = (index, parent) => {
    setTouchedValue(true);
    //  setRemoveState(true);
    let tempArr = selectedAttributes.filter((el, i) => i !== index);

    //  tempArr.filter((el, i) => i !== index);
    let results = allData?.filter(
      ({ parent: id1 }) => !tempArr.some(({ parent: id2 }) => id2 === id1)
    );
    const ind = results.findIndex((object) => {
      return object.parent === parent;
    });

    if (ind > 0) {
      results[ind].value = null;
    }
    //

    setSelectedAttributes(tempArr);
    setAllAttributes(results);
  };
  const handleChangeChecked = (value, index) => {
    setTouchedValue(true);
    let tempArray = [...selectedAttributes];
    if (value === true) {
      tempArray[index].value = "true";
    } else {
      tempArray[index].value = "false";
    }

    setSelectedAttributes(tempArray);
  };
  const handleAddNewData = () => {
    setTouchedValue(true);
    let obj = {
      _id: "",
      valueType: "",
      parent: "",
      displayNames: "",
      value: null,
      showDelete: true,
    };

    selectedAttributes.push(obj);
    setSelectedAttributes([...selectedAttributes]);
  };

  const handleChangeAttributeValue = (value, index, item) => {
    setTouchedValue(true);
    let tempArray = [...selectedAttributes];
    if (value !== null && value !== undefined && value !== "") {
      let obj = {
        _id: value?._id,
        valueType: value?.valueType,
        parent: value?.parent,
        displayNames: value?.displayNames,
        value: "false",
        showDelete: value?.showDelete ? value?.showDelete : item?.showDelete,
      };
      tempArray[index] = obj;
      selectedAttributes[index] = obj;
    } else {
      let obj = {
        _id: "",
        valueType: "",
        parent: "",
        displayNames: "",
        value: null,
        showDelete: true,
      };
      tempArray[index] = obj;
      selectedAttributes[index] = obj;
    }

    setSelectedAttributes([...selectedAttributes]);
    const results = allData.filter(
      ({ parent: id1 }) => !tempArray.some(({ parent: id2 }) => id2 === id1)
    );

    setAllAttributes(results);
  };

  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };

  return (
    <>
      {openDeleteModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("delete_description")}
            description={t("delete_message")}
            onConfirm={handleDeleteDescriptions}
            onCancel={handleCloseDeleteModel}
            loading={deleteLoading}
          />
        </Dialog>
      )}
      {openConfirmBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={openConfirmBox}
          //  onClose={handleCloseBox}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModalTwo
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={
              value === 0
                ? handleUpdateLocalPagesData
                : value === 1
                ? handleUpdateAttributes
                : value === 2
                ? handleUpdateDescription
                : handleUpdateCoverImages
            }
            onCancel={handleCloseBox}
            location={location}
            loading={isLoading}
          />
        </Dialog>
      )}
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          //  onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={
              value === 0
                ? handleUpdateLocalPagesData
                : value === 1
                ? handleUpdateAttributes
                : handleUpdateDescription
            }
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            getLocationDetails={getLocationDetails}
            location={location}

            //  loading={setIsLoading}
          />
        </Dialog>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container sx={{ padding: "18px" }}>
          <Grid item xs={10} sm={10} md={11.5} lg={11.5}>
            <Typography className={classes.modalHeader}>
              {t("local_pages")}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
            <IconButton
              onClick={() => {
                handleOpen();
                setCancelState(true);
              }}
              className="delete_button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />

        <Box ref={containerRef} className={classes.gridContainertwoBulk}>
          <Box className={classes.boxContainerMenus}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t("custom_fields")} {...a11yProps(0)} />
              <Tab label={t("custom_Attrributes")} {...a11yProps(1)} />
              <Tab label={t("Description")} {...a11yProps(2)} />
              <Tab label={t("cover_images")} {...a11yProps(3)} />
            </Tabs>
          </Box>
          {value === 0 && (
            <CustomFields
              isLoading={isLoading}
              localPagesData={localPagesData}
              setLocalPagesData={setLocalPagesData}
              clickAndConnectIsValid={clickAndConnectIsValid}
              deliverooIsValid={deliverooIsValid}
              deliverooRequired={deliverooRequired}
              justEatIsValid={justEatIsValid}
              justEatRequired={justEatRequired}
              uberEatsIsValid={uberEatsIsValid}
              uberEatsRequired={uberEatsRequired}
              menuLinkRequired={menuLinkRequired}
              menuLinkIsValid={menuLinkIsValid}
              clickAndConnectRequired={clickAndConnectRequired}
              setTouchedValue={setTouchedValue}
              slugIsValid={slugIsValid}
              slugRequired={slugRequired}
              setSlugRequired={setSlugRequired}
            />
          )}

          {value === 1 && (
            <Attributes
              isLoading={isLoading}
              setTouchedValue={setTouchedValue}
              location={location}
              attributes={selectedAttributes}
              allAttributes={allAttributes}
              handleAddAttribute={handleAddAttribute}
              handleRemoveAttribute={handleRemoveAttribute}
              handleChangeChecked={handleChangeChecked}
              handleAddNewData={handleAddNewData}
              handleChangeAttributeValue={handleChangeAttributeValue}
              hasEmpty={disableButton}
            />
          )}

          {value === 2 &&
            (isLoading ? (
              <>
                <Grid container className={classes.loadingGrid}>
                  <Loader />
                </Grid>
              </>
            ) : (
              <Description
                isLoading={isLoading}
                setTouchedValue={setTouchedValue}
                descriptionSetArray={descriptionSetArray}
                setDescriptionSetArray={setDescriptionSetArray}
                handleAdd={handleAddNewDescription}
                handleDelete={handleDeleteDescription}
                handleChangeLanguage={handleChangeLanguage}
                handleChangeDescription={handleChangeDescription}
                handleImportDescription={handleImportDescription}
                handleOpenDeleteModel={handleOpenDeleteModel}
                languagesDataArray={languagesDataArray}
                disableAddDescription={disableAddDescription}
                deleteLoading={deleteLoading}
              />
            ))}

          {value === 3 && (
            <CoverImages
              isLoading={isLoading}
              setTouchedValue={setTouchedValue}
              imagesShowData={imagesShowData}
              setImagesShowData={setImagesShowData}
              setDisplayConfirmationBox={setDisplayConfirmationBox}
              displayConfirmationBox={displayConfirmationBox}
              getLocationDetails={getLocationDetails}
              uploadImgError={uploadImgError}
              setUploadImgError={setUploadImgError}
              location={location}
              alreadyAddedImages={alreadyAddedImages}
              setAlreadyAddedImages={setAlreadyAddedImages}
              handleUpdateCoverImages={handleUpdateCoverImages}
              onCancel={handleClose}
              indexValue={indexValue}
              setSelectedTab={setSelectedTab}
              handleDeleteImage={handleDeleteImage}
              handleImagesShow={handleImagesShow}
              // handleAdd={handleAddNewDescription}
              // handleDelete={handleDeleteDescription}
              // handleChangeLanguage={handleChangeLanguage}
              // handleChangeDescription={handleChangeDescription}
              // handleImportDescription={handleImportDescription}
              // handleOpenDeleteModel={handleOpenDeleteModel}
              // languagesDataArray={languagesDataArray}
              // disableAddDescription={disableAddDescription}
            />
          )}
        </Box>

        <Divider />

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Close")}
            onSubmit={handleCancelButton}
          />
          <CommonButton
            disabled={
              disableButton
                ? true
                : isLoading
                ? true
                : menuLinkIsValid === false
                ? true
                : menuLinkRequired === true
                ? true
                : clickAndConnectIsValid === false
                ? true
                : clickAndConnectRequired === true
                ? true
                : deliverooIsValid === false
                ? true
                : deliverooRequired === true
                ? true
                : justEatIsValid === false
                ? true
                : justEatRequired === true
                ? true
                : uberEatsIsValid === false
                ? true
                : slugRequired === true
                ? true
                : uberEatsRequired === true
                ? true
                : false
            }
            onSubmit={
              value === 0
                ? handleUpdateLocalPagesData
                : value === 1
                ? handleUpdateAttributes
                : value === 2
                ? handleUpdateDescription
                : handleUpdateCoverImages
            }
            label={t("Save")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LocalPages;
