import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  tooltipClasses,
  Divider as MuiDivider,
  Box,
  TextField,
  Tooltip,
} from "@mui/material";
import styled from "styled-components/macro";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { api } from "../../../../../../../contexts/JWTContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CompanyContactmodal = ({
  onCancel,
  data,
  locationDetailsData,
  location,
  getLocationDetails,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  indexValue,
  setSelectedTab,
  getLocationDetailsListing,
  setIndexValue,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [cancelState, setCancelState] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [phoneRequired, setPhoneRequired] = useState(false);
  const [urlValidation, setUrlValidation] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [emailValidation, setEmailValidation] = useState(false);
  const [website, setWebsite] = useState(null);

  const urlRegexWebsite =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]+\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  useEffect(() => {
    setPhoneNumber(
      locationDetailsData?.result
        ? locationDetailsData?.result?.google?.phoneNumbers?.primaryPhone
        : ""
    );
    setWebsite(
      locationDetailsData?.result?.google?.websiteUri !== null
        ? locationDetailsData?.result?.google?.websiteUri
        : ""
    );
    setEmailValue(
      locationDetailsData?.result?.email !== null
        ? locationDetailsData?.result?.email
        : ""
    );
  }, []);

  const handleEmailChange = (event) => {
    setTouchedValue(true);
    if (event.target.value?.length > 0) {
      const email = event.target.value;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      const isValid = email === "" || emailRegex.test(email);

      setEmailValue(email);
      setEmailValidation(!isValid);
    } else {
      setEmailValue(event.target.value);
      setEmailValidation(false);
    }
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    setTouchedValue(true);
  };

  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      onCancel();
      getLocationDetails(location);
      setDisplayConfirmationBox(false);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };

  const handleUpdateCompanyDetail = async () => {
    if (
      urlValidation === false &&
      validPhoneNumber === false &&
      emailValidation === false
    ) {
      let removedDashes = "";
      if (
        phoneNumber !== undefined &&
        phoneNumber !== null &&
        phoneNumber !== ""
      ) {
        const phoneNumberEdited = phoneNumber?.replaceAll(" ", "");
        removedDashes = phoneNumberEdited?.replaceAll("-", "");
      }

      const dataWithPhone = {
        websiteUri: website !== null ? website : "",
        phoneNumbers: {
          primaryPhone: removedDashes,
        },
        email: emailValue,
      };

      try {
        setTouchedValue(false);
        setDisplayConfirmationBox(false);
        setIsLoading(true);

        const res = await api.patch(
          `/locationListing/update-contactInformation/${location?.id}`,
          dataWithPhone
        );

        if (indexValue === null) {
        } else {
          let selectedValue = indexValue;
          setSelectedTab(selectedValue);
        }

        if (cancelState === true) {
          onCancel();
          getLocationDetails(location);
        }

        if (res.status === 200) {
          setIsLoading(false);
          getLocationDetailsListing(location);
          toast.success("Updated Successfully");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    } else {
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    }
  };

  const handleWebsiteChange = (e) => {
    setTouchedValue(true);
    const url = e.target.value;
    setWebsite(url);
    if (url !== "") {
      const isValidUrl = urlRegexWebsite.test(url);
      setUrlValidation(!isValidUrl);
    } else {
      setUrlValidation(false);
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {displayConfirmationBox && (
        <Dialog
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateCompanyDetail}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            getLocationDetails={getLocationDetails}
            location={location}
          />
        </Dialog>
      )}
      <Grid container sx={{ padding: "18px" }}>
        <Grid
          item
          xs={10}
          sm={11}
          md={11.5}
          lg={11.5}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography className={classes.modalHeader}>
            {t("Contact_Details")}
          </Typography>
          <BootstrapTooltip title={t("Company_Details_subhead")}>
            <Typography
              sx={{
                marginLeft: "5px",
                marginTop: "4px",
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon />
            </Typography>
          </BootstrapTooltip>
        </Grid>
        <Grid item xs={2} sm={1} md={0.5} lg={0.5}>
          <IconButton
            onClick={() => {
              handleOpen();
              setCancelState(true);
            }}
            className="delete_button"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      {locationDetailsData !== null && (
        <Formik
          dirty={true}
          initialValues={{
            website: locationDetailsData?.result
              ? locationDetailsData?.result?.google?.websiteUri
              : "",

            emailAddress: data?.result ? data?.result?.google?.websiteUri : "",
            telephoneNumber: locationDetailsData?.result
              ? locationDetailsData?.result?.google?.phoneNumbers?.primaryPhone
              : "",
            faxNumber: data?.result ? data?.result?.websiteUri : "",
            countryName: data?.result ? data?.result?.google?.websiteUri : "",

            submit: false,
          }}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            handleUpdateCompanyDetail();
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form>
              <Grid container className={classes.gridContainertwoBulk}>
                {isLoading ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.loaderBox}
                    >
                      <Loader />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container spacing={2} sx={{ padding: "18px" }}>
                      <Grid item md={6} sm={6} xs={12} lg={6}>
                        <Box sx={{ display: "flex" }}>
                          <Typography className={classes.Details}>
                            {t("Telephone_Number")}
                          </Typography>
                        </Box>

                        <TextField
                          InputProps={{
                            inputProps: {
                              style: {
                                marginLeft: "10px",
                                marginRight: "10px",
                              },
                            },
                          }}
                          // type="number"
                          name="telephoneNumber"
                          error={validPhoneNumber}
                          fullWidth
                          helperText={
                            touched.telephoneNumber && errors.telephoneNumber
                          }
                          value={phoneNumber}
                          placeholder="e.g. +123 123 1231232"
                          onChange={(e) => {
                            handlePhoneChange(e.target.value);
                            /*  if (e.target.value === "+") {
                              // handlePhoneChange(e.target.value);
                              setValidPhoneNumber(false);
                              setDisplayWarningMessage(true);
                            }  */
                            if (e.target.value.length > 0) {
                              //  setPhoneRequired(false);
                              if (
                                e.target.value.length < 8 ||
                                e.target.value.length > 14
                              ) {
                                setValidPhoneNumber(true);
                                // setDisplayWarningMessage(false);
                              } else {
                                // setDisplayWarningMessage(false);
                                setValidPhoneNumber(false);
                              }
                            } else {
                              //  setPhoneRequired(true);
                              setValidPhoneNumber(false);
                            }
                          }}
                        />
                        {phoneRequired && (
                          <Typography className={classes.errorText}>
                            {t("telephone_req")}
                          </Typography>
                        )}
                        {validPhoneNumber && (
                          <Typography className={classes.errorText}>
                            {t("not_valid_number")}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={6} sm={6} xs={6} lg={6}>
                        <Typography className={classes.Details}>
                          {t("email")}
                        </Typography>
                        <TextField
                          type="text"
                          name="email"
                          error={emailValidation}
                          fullWidth
                          placeholder="e.g. john@obenan.com"
                          helperText={emailValidation ? "Invalid email" : ""}
                          value={emailValue !== null ? emailValue : ""}
                          // onBlur={handleBlur}
                          onChange={handleEmailChange}
                        />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        lg={12}
                        sx={{ marginTop: "-170px" }}
                      >
                        <Typography className={classes.Details}>
                          {t("Website")}
                        </Typography>
                        <TextField
                          InputProps={{
                            inputProps: {
                              style: {
                                marginLeft: "10px",
                                marginRight: "10px",
                              },
                            },
                          }}
                          type="text"
                          name="website"
                          error={urlValidation}
                          fullWidth
                          placeholder="e.g. https://obenan.com/"
                          helperText={urlValidation && "Invalid URL"}
                          value={website !== null ? website : ""}
                          onChange={handleWebsiteChange}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Divider />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.gridButtons}
              >
                <CommonButton
                  displayWhite="true"
                  label={t("Close")}
                  onSubmit={handleCancelButton}
                />
                <CommonButton
                  disabled={
                    isSubmitting ||
                    validPhoneNumber ||
                    phoneRequired ||
                    urlValidation ||
                    emailValidation
                  }
                  type="submit"
                  label={t("Save")}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Grid>
  );
};

export default CompanyContactmodal;
