// ** React Imports ================================================================
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import Link from "@mui/material/Link";
import Person from "../../../../assets/images/person.svg";
import Skull from "../../../../assets/images/Skull.svg";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// ** File Imports ================================================================

import TitleHeader from "../../../../components/CustomComponents/title";
import { api } from "../../../../contexts/JWTContext";
import Loader from "../../../../components/Loaders/Loader";

// ** Material ui Imports ================================================================
import {
  Autocomplete,
  createFilterOptions,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  tooltipClasses,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import { WarningAmberOutlined } from "@mui/icons-material";
import { languagesData } from "../../../../assets/defaultLanguage";
import { Box } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useStyles } from "../styles/style";
import PostAIModal from "./PromptModel";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import AddBusinessModel from "./Models/AddBusinessEmailModel";
import AddBulkEmailModel from "./Models/bulkUpdateModel";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// ** Other Imports ================================================================

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
//const Alert = styled(MuiAlert)(spacing);

const Ratings = [
  { id: 1, name: "1_star", value: "one" },
  { id: 2, name: "2_star", value: "two" },
  { id: 3, name: "3_star", value: "three" },
  { id: 4, name: "4_star", value: "four" },
  { id: 5, name: "5_star", value: "five" },
];

const state = [{ label: "Present" }, { label: "Not Present" }];
const AddRule = () => {
  // ** Others ================================================================
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const classes = useStyles();
  const tagsGridRef = useRef(null);
  const tagsGridRefTwo = useRef(null);
  const langGridRef = useRef(null);
  const nameGridRef = useRef(null);
  const locationGridRef = useRef(null);
  const ratingGridRef = useRef(null);
  const templateGridRef = useRef(null);
  // ** States ================================================================
  const [dataToEdit, setDataToEdit] = useState(null);
  const [allTemplate, setAllTemplate] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(null);
  const [selectedRating, setSelectedRating] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [aiReview, setAIReview] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [templateRequired, setTemplateRequired] = useState(false);
  const [reviewRequired, setReviewRequired] = useState(false);
  const [ratingRequired, setRatingRequired] = useState(false);
  const [selectedReviewOption, setSelectedReviewOption] = useState([]);
  const [reviewPresent, setReviewPresent] = useState(false);

  const [reviewNotPresent, setReviewNotPresent] = useState(false);
  /*   const [message, setMessage] = useState("");
   */ const [errors, setErrors] = useState("");

  const [errorRules, setErrorRules] = useState([]);
  const [titleError, setTitleError] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const filter = createFilterOptions();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [selectedPrmopt, setSelectedPrompt] = useState("default");
  const [openPrmoptModel, setOpenPrmoptModel] = useState(false);
  const [tagsInclude, setTagsInclude] = useState([]);
  const [tagsExclude, setTagsExclude] = useState([]);
  const [tagsIncludeNotPresent, setTagsIncludeNotPresent] = useState([]);
  const [tagsExcludeNotPresent, setTagsExcludeNotPresent] = useState([]);
  const [formalChecked, setFormalChecked] = useState(true);
  const [formalCheckedTwo, setFormalCheckedTwo] = useState(true);
  const [reviewToneChecked, setReviewToneChecked] = useState(false);
  const [reviewToneCheckedTwo, setReviewToneCheckedTwo] = useState(false);
  const [friendlyChecked, setFriendlyChecked] = useState(false);
  const [friendlyCheckedTwo, setFriendlyCheckedTwo] = useState(false);
  const [includeChecked, setIncludeChecked] = useState(false);
  const [includeCheckedTwo, setIncludeCheckedTwo] = useState(false);
  const [excludeChecked, setExcludeChecked] = useState(false);
  const [excludeCheckedTwo, setExcludeCheckedTwo] = useState(false);
  const [yesChecked, setYesChecked] = useState(false);
  const [yesCheckedTwo, setYesCheckedTwo] = useState(false);
  const [noChecked, setNoChecked] = useState(true);
  const [noCheckedTwo, setNoCheckedTwo] = useState(true);
  const [moreLanguageChecked, setMoreLanguageChecked] = useState(false);
  const [moreLanguageCheckedTwo, setMoreLanguageCheckedTwo] = useState(false);
  const [userLanguageChecked, setUserLanguageChecked] = useState(false);
  const [userLanguageCheckedTwo, setUserLanguageCheckedTwo] = useState(true);
  const [userLanguage, setUserLanguage] = useState([]);
  const [userLanguageTwo, setUserLanguageTwo] = useState([]);
  const [reviewLanguageChecked, setReviewLanguageChecked] = useState(true);
  const [extraData, setExtraData] = useState("");
  const [displayToast, setDisplayToast] = useState(false);
  const [extraDataTwo, setExtraDataTwo] = useState("");
  const i18nextLng = localStorage.getItem("i18nextLng");
  const [userTranslationLanguage, setUserTranslationLanguage] =
    React.useState(i18nextLng);
  const [userTranslationLanguageTwo, setUserTranslationLanguageTwo] =
    React.useState(i18nextLng);
  const [selectAllPresent, setSelectAllPresent] = useState(false);
  const [selectAllNotPresent, setSelectAllNotPresent] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [singleChecked, setSingleChecked] = useState(false);
  const [singleCheckedTwo, setSingleCheckedTwo] = useState(false);
  const [multiChecked, setMultiChecked] = useState(false);
  const [multiCheckedTwo, setMultiCheckedTwo] = useState(false);
  const [tagsRequired, setTagsRequired] = useState(false);
  const [tagsRequiredTwo, setTagsRequiredTwo] = useState(false);
  const [languageRequired, setLanguageRequired] = useState(false);
  const [languageRequiredTwo, setLanguageRequiredTwo] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [scrollStateTags, setScrollStateTags] = useState(false);
  const [scrollStateTagsTwo, setScrollStateTagsTwo] = useState(false);
  const [scrollStateLang, setScrollStateLang] = useState(false);
  const [scrollStateLangTwo, setScrollStateLangTwo] = useState(false);
  const [locationsToEdit, setLocationsToEdit] = useState([]);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [displayEmailWarning, setDisplayEmailWarning] = useState(false);
  const [openUpdateModel, setOpenUpdateModel] = useState(false);
  const [businessEmailLocations, setBusinessEmailLocations] = useState([]);
  const [openAddEmailModel, setOpenAddEmailModel] = useState(false);
  const [openAddBulkEmailModel, setOpenAddBulkEmailModel] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [alreadySelectedLocation, setAlreadySelectedLocations] = useState([]);
  const [selectAllRating, setSelectAllRating] = useState(false);
  const [searchParams] = useSearchParams();
  const prefilled = searchParams.get("prefilled");
  const [excludeNameChecked, setExcludeNameChecked] = useState(false);
  const [excludeNameCheckedtwo, setExcludeNameCheckedTwo] = useState(false);
  const [firstNameChecked, setFirstNameChecked] = useState(false);
  const [firstNameCheckedTwo, setFirstNameCheckedTwo] = useState(false);
  const [fullNameChecked, setFullNameChecked] = useState(true);
  const [fullNameCheckedTwo, setFullNameCheckedTwo] = useState(true);
  const [defaultEmailchecked, setDefualtEmailChecked] = useState(true);
  const [defaultEmailcheckedTwo, setDefualtEmailCheckedTwo] = useState(true);
  const [customEmailchecked, setCustomEmailChecked] = useState(false);
  const [customEmailcheckedTwo, setCustomEmailCheckedTwo] = useState(false);
  const [emailValue, setEmailValue] = useState(null);
  const [emailValueTwo, setEmailValueTwo] = useState(null);
  const [emailValidation, setEmailValidation] = useState(false);
  const [emailValidationTwo, setEmailValidationTwo] = useState(false);
  const [signatureValue, setSignatureValue] = useState(null);
  const [signatureValueTwo, setSignatureValueTwo] = useState(null);
  const [customLengthValue, setCustomLengthValue] = useState(2);
  const [customLengthValueTwo, setCustomLengthValueTwo] = useState(2);
  const [defaultPromptWithText, setDefaultPromptWithText] = useState(
    "A customer name {{-client-}} has left a review with text for a location called {{-locationName-}} located at {{-cityName-}} (email: {{businessEmail}}) Star Rating: {{-ratingStars-}} Review: {{-reviewText-}}, Please address the customer by their name and provide a polite and constructive response to this rating with text same as review language using a casual tone and adjusting the tone according to the star rating. Keep the response in one paragraph without any line breaks, and explicitly avoid adding any signatures. Limit the response to two sentences.Also please make sure the response flows naturally and feels human"
  );

  const [defaultPromptWithOutText, setDefaultPromptWithOutText] = useState(
    "A customer named {{-client-}} has left a review without text for a location called {{-locationName-}} located at {{-cityName-}} (email: {{businessEmail}}) Star Rating: {{-ratingStars-}}, Please address the customer by their name and provide a polite and constructive response to this rating without text in {{-languageName-}}, using a casual tone using a casual tone and adjusting the tone according to the star rating. Keep the response in one paragraph without any line breaks, and explicitly avoid adding any signatures. Limit the response to two sentences.Also please make sure the response flows naturally and feels human."
  );

  const [textPresentPrompt, setTextPresentPrompt] = useState(
    "A customer {{-nameOfCustomer-}} has left a review with text for a location called {{-locationName-}} located at {{-cityName-}} (email: {{businessEmail}}) Star Rating: {{-ratingStars-}} Review: {{-reviewText-}}. Please address the customer by their name and provide a polite and constructive response to this rating in {{-languageOfPrompt-}}, adopting a {{-toneOfPrompt-}} and adjusting it based on the star rating. Keep the response, and {{-signatureOfPrompt-}}. Limit the response to {{-responseLength-}} sentences. Use the pronoun {{-pronounOfPrompt-}} to address customer. {{-phraseOfPrompt-}} phrases like {{-keywords-}} to express appreciation without repeating the same expressions. Also please make sure the response flows naturally and feels human."
  );
  const [textNotPresentPrompt, setTextNotPresentPrompt] = useState(
    "A customer {{-nameOfCustomer-}} has left a review without text for a location called {{-locationName-}} located at {{-cityName-}} (email: {{businessEmail}}) Star Rating: {{-ratingStars-}}. Please address the customer by their name and provide a polite and constructive response to this rating in {{-locationLanguage-}}, adopting a {{-toneOfPrompt-}} and adjusting it based on the star rating. Keep the response, and {{-signatureOfPrompt-}}. Limit the response to {{-responseLength-}} sentences. Use the pronoun {{-pronounOfPrompt-}} to address customer.{{-phraseOfPrompt-}} phrases like {{-keywords-}} to express appreciation without repeating the same expressions. Also please make sure the response flows naturally and feels human."
  );
  const [modelPresentPrompt, setModelPresentPrompt] =
    useState(textPresentPrompt);
  const [modelNotPresentPrompt, setModelNotPresentPrompt] =
    useState(textNotPresentPrompt);
  const [autoSelect, setAutoSelect] = useState(true);
  const [touchedValue, setTouchedValue] = useState(false);

  useEffect(() => {
    if (id !== undefined) {
      getRuleToEdit();
    }
  }, []);
  useEffect(() => {
    if (id === undefined) {
      setTextPresentPrompt(
        "A customer {{-fullName-}} has left a review with text for a location called {{-locationName-}} located at {{-cityName-}} (email: {{businessEmail}}) Star Rating: {{-ratingStars-}} Review: {{-reviewText-}}. Please address the customer by their name and provide a polite and constructive response to this rating in {{-reviewLanguage-}}, adopting a {{-formalTone-}} and adjusting it based on the star rating. Keep the response, and {{-excludeSignature-}}. Limit the response to {{-responseLength-}} sentences.{{-selectedExtras-}}. Also please make sure the response flows naturally and feels human."
      );
      setTextNotPresentPrompt(
        "A customer {{-fullName-}} has left a review without text for a location called {{-locationName-}} located at {{-cityName-}} (email: {{businessEmail}}) Star Rating: {{-ratingStars-}}. Please address the customer by their name and provide a polite and constructive response to this rating in {{-locationLanguage-}}, adopting a {{-formalTone-}} and adjusting it based on the star rating. Keep the response, and {{-excludeSignature-}}. Limit the response to {{-responseLength-}} sentences.{{-selectedExtras-}}. Also please make sure the response flows naturally and feels human."
      );
      setModelPresentPrompt(
        "A customer {{-fullName-}} has left a review with text for a location called {{-locationName-}} located at {{-cityName-}} (email: {{businessEmail}}) Star Rating: {{-ratingStars-}} Review: {{-reviewText-}}. Please address the customer by their name and provide a polite and constructive response to this rating in {{-reviewLanguage-}}, adopting a {{-formalTone-}} and adjusting it based on the star rating. Keep the response, and {{-excludeSignature-}}. Limit the response to {{-responseLength-}} sentences.{{-selectedExtras-}}. Also please make sure the response flows naturally and feels human."
      );
      setModelNotPresentPrompt(
        "A customer {{-fullName-}} has left a review without text for a location called {{-locationName-}} located at {{-cityName-}} (email: {{businessEmail}}) Star Rating: {{-ratingStars-}}. Please address the customer by their name and provide a polite and constructive response to this rating in {{-locationLanguage-}}, adopting a {{-formalTone-}} and adjusting it based on the star rating. Keep the response, and {{-excludeSignature-}}. Limit the response to {{-responseLength-}} sentences.{{-selectedExtras-}}. Also please make sure the response flows naturally and feels human."
      );
    }
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      if (selectedLocation?.length > 0 && locationsToEdit?.length > 0) {
        if (selectedLocation?.length === locationsToEdit?.length) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }
      }
    }
  }, [selectedLocation, locationsToEdit]);

  useEffect(() => {
    if (dataToEdit !== null) {
      let tempLocationArray = [];
      dataToEdit?.ruleLocation?.map((locationItem) => {
        tempLocationArray.push(locationItem?.location);
      });
      const defaultlocation = tempLocationArray?.find(
        (item, index) => item.defaultLocation !== null
      );
      console.log(tempLocationArray, "tempLocationArray");
      if (defaultlocation !== null && defaultlocation !== undefined) {
        setDefaultLocation(defaultlocation);
      } else {
        setDefaultLocation(tempLocationArray[0]);
      }

      let arr = [...tempLocationArray];
      allLocations?.results?.map((item) => {
        arr.map((itemTwo) => {
          if (item?.id === itemTwo?.id) {
            itemTwo["businessEmail"] = item?.businessEmail;
          }
        });
      });
      setSelectedLocation(arr);
      //  setSelectedLocation(tempLocationArray);
      setLocationRequired(false);
      if (allLocations?.results !== undefined) {
        const results = tempLocationArray.filter(
          ({ id: id1 }) =>
            !allLocations?.results.some(({ id: id2 }) => id2 === id1)
        );

        const missingEmailLocations = tempLocationArray?.filter(
          (item) => item?.businessEmail === "" || item?.businessEmail === null
        );

        const mergedArraySelected = results.concat(missingEmailLocations);

        setAlreadySelectedLocations(mergedArraySelected);

        if (results?.length > 0) {
          setDisplayWarning(true);
        } else {
          setDisplayWarning(false);
        }
        const mergedArray = [...tempLocationArray, ...allLocations?.results];
        let uniqueArray = mergedArray.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setLocationsToEdit(uniqueArray);
      }

      /*   let newTemplate = [];
      dataToEdit?.ruleTemplate?.map((template) => {
        newTemplate.push(template.template);
      });
      setSelectedTemplate(newTemplate); */

      let newTempArray = [];
      dataToEdit?.reviewTextPresent === true &&
        newTempArray.push({ label: "Present" });
      dataToEdit?.reviewTextNotPresent === true &&
        newTempArray.push({ label: "Not Present" });

      setSelectedReviewOption(newTempArray);
      if (dataToEdit?.ruleTemplate?.length > 0) {
        const templatesByRating = dataToEdit?.ruleTemplate.reduce(
          (acc, item) => {
            const rating = item.ratingStar;
            if (!acc[rating]) {
              acc[rating] = [];
            }
            acc[rating].push(item.template);
            return acc;
          },
          {}
        );

        // Step 2: Create the final array, including only ratings that have templates
        const result = Ratings.filter(
          (rating) =>
            templatesByRating[rating.value] &&
            templatesByRating[rating.value].length > 0
        ).map((rating) => ({
          id: rating.id,
          name: rating.name,
          value: rating.value,
          template: templatesByRating[rating.value],
        }));
        if (result?.length === 5) {
          setSelectAllRating(true);
        } else {
          setSelectAllRating(false);
        }

        setSelectedRating(result);
      } else {
        const matchingRatings = Ratings.filter((rating) =>
          dataToEdit?.ratingStar.includes(rating.value)
        ) // Filter based on ratingStar
          .map((rating) => ({
            ...rating,
            template: [], // Add an empty templates array
          }));
        if (matchingRatings?.length === 5) {
          setSelectAllRating(true);
        } else {
          setSelectAllRating(false);
        }
        setSelectedRating(matchingRatings);
      }

      setAutoSelect(dataToEdit?.samePromptProperties);
      setAIReview(dataToEdit?.isSupervised);
      setTitle(dataToEdit?.title);
      if (dataToEdit?.defaultPrompt === false) {
        setSelectedPrompt("custom");

        let textPresentFormatedValue = handleChangeTextPresentPromptProperties(
          textPresentPrompt,
          dataToEdit?.promptWithTextProperties,
          true
        );
        let textNotPresentFormatedValue =
          handleChangeTextPresentPromptProperties(
            textNotPresentPrompt,
            dataToEdit?.promptWithOutTextProperties,
            false
          );

        setModelNotPresentPrompt(textNotPresentFormatedValue);
        setTextNotPresentPrompt(textNotPresentFormatedValue);
        setModelPresentPrompt(textPresentFormatedValue);
        setTextPresentPrompt(textPresentFormatedValue);
      } else {
        setSelectedPrompt("default");
        setDefaultPromptWithText(dataToEdit?.promptWithText);
        setDefaultPromptWithOutText(dataToEdit?.promptWithOutText);
      }

      if (dataToEdit?.promptWithTextProperties !== null) {
        setCustomLengthValue(
          dataToEdit?.promptWithTextProperties?.responseLength
        );
        setExtraData(dataToEdit?.promptWithTextProperties?.extraData);
        setTagsInclude(dataToEdit?.promptWithTextProperties?.includeKeywords);
        setTagsExclude(dataToEdit?.promptWithTextProperties?.excludeKeywords);
        if (dataToEdit?.promptWithTextProperties?.businessEmail !== null) {
          setDefualtEmailChecked(false);
          setCustomEmailChecked(true);
          setEmailValue(dataToEdit?.promptWithTextProperties?.businessEmail);
        } else {
          setDefualtEmailChecked(true);
          setCustomEmailChecked(false);
          setEmailValue(null);
        }

        if (
          dataToEdit?.promptWithTextProperties?.nameOfCustomer === "fullName"
        ) {
          setFullNameChecked(true);
          setFirstNameChecked(false);
          setExcludeNameChecked(false);
        } else if (
          dataToEdit?.promptWithTextProperties?.nameOfCustomer === "firstName"
        ) {
          setFullNameChecked(false);
          setFirstNameChecked(true);
          setExcludeNameChecked(false);
        } else if (
          dataToEdit?.promptWithTextProperties?.nameOfCustomer === "Guest"
        ) {
          setFullNameChecked(false);
          setFirstNameChecked(false);
          setExcludeNameChecked(true);
        }

        if (
          dataToEdit?.promptWithTextProperties?.toneOfPrompt === "formalTone"
        ) {
          setFormalChecked(true);
          setFriendlyChecked(false);
          setReviewToneChecked(false);
        } else if (
          dataToEdit?.promptWithTextProperties?.toneOfPrompt === "friendlyTone"
        ) {
          setFormalChecked(false);
          setFriendlyChecked(true);
          setReviewToneChecked(false);
        } else {
          setFormalChecked(false);
          setFriendlyChecked(false);
          setReviewToneChecked(true);
        }
        if (dataToEdit?.promptWithTextProperties?.pronounOfPrompt === null) {
          setMultiChecked(false);
          setSingleChecked(false);
        } else if (
          dataToEdit?.promptWithTextProperties?.pronounOfPrompt ===
          "singularPronoun"
        ) {
          setMultiChecked(false);
          setSingleChecked(true);
        } else {
          setMultiChecked(true);
          setSingleChecked(false);
        }
        setTagsInclude(dataToEdit?.promptWithTextProperties?.includeKeywords);
        setTagsExclude(dataToEdit?.promptWithTextProperties?.excludeKeywords);

        if (
          dataToEdit?.promptWithTextProperties?.signatureOfPrompt ===
          "includeSignature"
        ) {
          setYesChecked(true);
          setNoChecked(false);
          setSignatureValue(
            dataToEdit?.promptWithTextProperties?.signatureText
          );
        } else {
          setYesChecked(false);
          setNoChecked(true);
          setSignatureValue(null);
        }
        if (
          dataToEdit?.promptWithTextProperties?.languageOfPrompt ===
          "locationLanguage"
        ) {
          setUserLanguageChecked(true);
          setReviewLanguageChecked(false);
          setMoreLanguageChecked(false);
        } else if (
          dataToEdit?.promptWithTextProperties?.languageOfPrompt ===
          "reviewLanguage"
        ) {
          setUserLanguageChecked(false);
          setReviewLanguageChecked(true);
          setMoreLanguageChecked(false);
        } else {
          setUserLanguageChecked(false);
          setReviewLanguageChecked(false);
          setMoreLanguageChecked(true);
          setUserLanguage(dataToEdit?.promptWithTextProperties?.languageName);
        }
      }
      if (dataToEdit?.promptWithOutTextProperties !== null) {
        setCustomLengthValueTwo(
          dataToEdit?.promptWithOutTextProperties?.responseLength
        );
        setExtraDataTwo(dataToEdit?.promptWithOutTextProperties?.extraData);
        setTagsIncludeNotPresent(
          dataToEdit?.promptWithOutTextProperties?.includeKeywords
        );
        setTagsExcludeNotPresent(
          dataToEdit?.promptWithOutTextProperties?.excludeKeywords
        );

        if (
          dataToEdit?.promptWithOutTextProperties?.nameOfCustomer === "fullName"
        ) {
          setFullNameCheckedTwo(true);
          setFirstNameCheckedTwo(false);
          setExcludeNameCheckedTwo(false);
        } else if (
          dataToEdit?.promptWithOutTextProperties?.nameOfCustomer ===
          "firstName"
        ) {
          setFullNameCheckedTwo(false);
          setFirstNameCheckedTwo(true);
          setExcludeNameCheckedTwo(false);
        } else {
          setFullNameCheckedTwo(false);
          setFirstNameCheckedTwo(false);
          setExcludeNameCheckedTwo(true);
        }

        if (dataToEdit?.promptWithOutTextProperties?.businessEmail !== null) {
          setDefualtEmailCheckedTwo(false);
          setCustomEmailCheckedTwo(true);
          setEmailValueTwo(
            dataToEdit?.promptWithOutTextProperties?.businessEmail
          );
        } else {
          setDefualtEmailCheckedTwo(true);
          setCustomEmailCheckedTwo(false);
          setEmailValueTwo(null);
        }

        if (dataToEdit?.promptWithOutTextProperties?.pronounOfPrompt === null) {
          setMultiCheckedTwo(false);
          setSingleCheckedTwo(false);
        } else if (
          dataToEdit?.promptWithOutTextProperties?.pronounOfPrompt ===
          "singularPronoun"
        ) {
          setMultiCheckedTwo(false);
          setSingleCheckedTwo(true);
        } else {
          setMultiCheckedTwo(true);
          setSingleCheckedTwo(false);
        }
        if (
          dataToEdit?.promptWithOutTextProperties?.toneOfPrompt === "formalTone"
        ) {
          setFormalCheckedTwo(true);
          setFriendlyCheckedTwo(false);
          setReviewToneCheckedTwo(false);
        } else if (
          dataToEdit?.promptWithOutTextProperties?.toneOfPrompt ===
          "friendlyTone"
        ) {
          setFormalCheckedTwo(false);
          setFriendlyCheckedTwo(true);
          setReviewToneCheckedTwo(false);
        } else {
          setFormalCheckedTwo(false);
          setFriendlyCheckedTwo(false);
          setReviewToneCheckedTwo(true);
        }
        setTagsIncludeNotPresent(
          dataToEdit?.promptWithOutTextProperties?.includeKeywords
        );
        setTagsExcludeNotPresent(
          dataToEdit?.promptWithOutTextProperties?.excludeKeywords
        );

        if (
          dataToEdit?.promptWithOutTextProperties?.signatureOfPrompt ===
          "includeSignature"
        ) {
          setYesCheckedTwo(true);
          setNoCheckedTwo(false);
          setSignatureValueTwo(
            dataToEdit?.promptWithOutTextProperties?.signatureText
          );
        } else {
          setYesCheckedTwo(false);
          setNoCheckedTwo(true);
          setSignatureValueTwo(null);
        }
        /*   if (
          dataToEdit?.promptWithOutTextProperties?.language === "userLanguage"
        ) {
          setUserLanguageCheckedTwo(true);
          //  setReviewLanguageChecked(false);
          setMoreLanguageCheckedTwo(false);
        } else if (
          dataToEdit?.promptWithOutTextProperties?.language === "reviewLanguage"
        ) {
          setUserLanguageCheckedTwo(false);
          //  setReviewLanguageChecked(true);
          setMoreLanguageCheckedTwo(false);
        } else {
          setUserLanguageCheckedTwo(false);
          //  setReviewLanguageChecked(false);
          setMoreLanguageCheckedTwo(true);
          setUserLanguage(
            dataToEdit?.promptWithOutTextProperties?.languageName
          );
        } */
      }
    }
  }, [dataToEdit, allLocations]);

  useEffect(() => {
    const getAllLocations = async () => {
      setIsLoading(true);
      try {
        const res = await api.get(`/location/search`, {
          params: {
            isLocationPage: false,
            // searchTerm: filterWithName,
            // subscriptionIds: temp?.length > 0 ? temp.toString() : "",
          },
        });
        if (res.status === 200) {
          setIsLoading(true);
          setAllLocations(res.data.data);
          if (res.data.data?.results?.length > 0) {
            const filteredData = res.data.data?.results?.filter(
              (item) =>
                item?.businessEmail === null || item?.businessEmail === ""
            );
            console.log(filteredData, "filteredData");
            setBusinessEmailLocations(filteredData);
            if (filteredData?.length > 0) {
              setDisplayEmailWarning(true);
            } else {
              setDisplayEmailWarning(false);
            }
            if (id === undefined) {
              const defaultlocation = res.data.data?.results?.find(
                (item, index) =>
                  item.defaultLocation !== null &&
                  item?.businessEmail !== null &&
                  item?.businessEmail !== ""
              );

              if (defaultlocation !== null && defaultlocation !== undefined) {
                setDefaultLocation(defaultlocation);

                if (id === undefined && prefilled === null) {
                  setSelectedLocation([defaultlocation]);
                }
              } else {
                const filteredData = res.data.data?.results?.filter(
                  (item) =>
                    item?.businessEmail !== null && item?.businessEmail !== ""
                );
                const earliestSubscription = filteredData?.reduce(
                  (earliest, current) => {
                    return new Date(current.subscriptionDate) <
                      new Date(earliest.subscriptionDate)
                      ? current
                      : earliest;
                  }
                );

                setDefaultLocation(earliestSubscription);
                if (id === undefined && prefilled === null) {
                  setSelectedLocation([earliestSubscription]);
                }
              }
            }
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    getAllLocations();
  }, []);

  useEffect(() => {
    getAllTemplate();
  }, []);

  useEffect(() => {
    let reviewIncludes = "Present";
    let reviewNotIncludes = "Not Present";

    const reviews = selectedReviewOption?.map((item) => item?.label);
    const reviewPresent = reviews?.includes(reviewIncludes);
    const reviewNotPresent = reviews?.includes(reviewNotIncludes);

    setReviewPresent(reviewPresent);
    setReviewNotPresent(reviewNotPresent);
  }, [selectedReviewOption]);

  const getRuleToEdit = async () => {
    try {
      const res = await api.get(`/rule/${id}`);
      if (res.status === 200) {
        setIsLoading(true);
        setDataToEdit(res?.data?.data);
        setIsLoading(false);
      }
      // setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  function getMatchingItemsWithEmptyEmail(arr1, arr2) {
    const idSetFromArr1 = [...new Set(arr1.map((item) => item.id))];
    const matchingItems = arr2.filter(
      (item) =>
        idSetFromArr1.includes(item.id) &&
        (item.businessEmail === null || item.businessEmail === "")
    );
    return matchingItems.length > 0;
  }

  const scrollToRatingGrid = () => {
    if (ratingGridRef.current) {
      ratingGridRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const updateRule = async (presentText, notPresentText, navigateBool) => {
    // Extracting ids from location and template to send to db =====================
    /*  let templateIds = [];
    selectedTemplate?.map((template) => {
      templateIds.push(template?.id);
    }); */
    let locationFound = false;

    if (selectedLocation?.length > 0) {
      const matchingItems = getMatchingItemsWithEmptyEmail(
        allLocations?.results,
        selectedLocation
      );
      locationFound = matchingItems;
    }
    if (locationFound) {
      setUpdateLoading(false);
      toast?.error(t("businessEmailError"));
      handleCloseUpdateModel();
    } else {
      let locationIds = [];
      selectedLocation?.map((location) => {
        locationIds.push(location?.id);
      });

      if (title === null) {
        setTitleError(true);
        nameGridRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      /*   if (templateIds.length < 1) {
        setTemplateRequired(true);
      } */
      if (selectedLocation.length < 1) {
        setLocationRequired(true);
        locationGridRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      if (selectedRating?.length === 0) {
        setRatingRequired(true);
        scrollToRatingGrid();
      }
      if (selectedReviewOption.length < 1) {
        setReviewRequired(true);
        scrollToRatingGrid();
      }

      const promptWithTextProperties = {
        nameOfCustomer:
          firstNameChecked &&
          fullNameChecked === false &&
          excludeNameChecked === false
            ? "firstName"
            : firstNameChecked === false &&
              fullNameChecked &&
              excludeNameChecked === false
            ? "fullName"
            : "Guest",
        toneOfPrompt: formalChecked
          ? "formalTone"
          : friendlyChecked
          ? "friendlyTone"
          : "reviewTone",

        includeKeywords: tagsInclude,
        excludeKeywords: tagsExclude,
        // length: shortChecked ? "short" : detailedCheked ? "detailed" : "custom",
        responseLength: customLengthValue,
        signatureOfPrompt: yesChecked ? "includeSignature" : "excludeSignature",
        signatureText: yesChecked ? signatureValue : null,
        businessEmail: defaultEmailchecked ? null : emailValue,
        languageOfPrompt: userLanguageChecked
          ? "locationLanguage"
          : reviewLanguageChecked
          ? "reviewLanguage"
          : "multipleLanguage",
        languageName: moreLanguageChecked ? userLanguage : null,
        extraData: extraData,
        pronounOfPrompt: singleChecked
          ? "singularPronoun"
          : multiChecked
          ? "pluralPronoun"
          : null,
      };
      const promptWithOutTextProperties = {
        nameOfCustomer:
          firstNameCheckedTwo &&
          fullNameCheckedTwo === false &&
          excludeNameCheckedtwo === false
            ? "firstName"
            : firstNameCheckedTwo === false &&
              fullNameCheckedTwo &&
              excludeNameCheckedtwo === false
            ? "fullName"
            : "Guest",
        toneOfPrompt: formalCheckedTwo
          ? "formalTone"
          : friendlyCheckedTwo
          ? "friendlyTone"
          : "reviewTone",
        includeKeywords: tagsIncludeNotPresent,
        excludeKeywords: tagsExcludeNotPresent,
        /*  length: shortCheckedTwo
        ? "short"
        : detailedChekedTwo
        ? "detailed"
        : "custom", */
        responseLength: customLengthValueTwo,
        signatureOfPrompt: yesCheckedTwo
          ? "includeSignature"
          : "excludeSignature",
        signatureText: yesCheckedTwo ? signatureValue : null,
        businessEmail: defaultEmailcheckedTwo ? null : emailValueTwo,
        // languageOfPrompt: userLanguageCheckedTwo ? "userLanguage" : "more",
        // languageName: moreLanguageCheckedTwo ? userLanguageTwo : null,
        extraData: extraDataTwo,
        pronounOfPrompt: singleCheckedTwo
          ? "singularPronoun"
          : multiCheckedTwo
          ? "pluralPronoun"
          : null,
      };
      let templateIds = [];
      if (aiReview === true) {
        selectedRating?.map((item) => {
          item?.template?.map((itemTwo) => {
            let obj = {
              id: itemTwo?.id,
              ratingStar: item?.value,
            };
            templateIds?.push(obj);
          });
        });
      }

      const data = {
        title,
        templateIds: aiReview === true ? templateIds : null,
        locationIds: locationIds,
        ratingStar: selectedRating.map((rating) => rating?.value),
        reviewTextPresent: reviewPresent,
        reviewTextNotPresent: reviewNotPresent,
        isSupervised: aiReview,
        defaultPrompt: selectedPrmopt === "default" ? true : false,
        samePromptProperties: autoSelect,
        promptWithText:
          selectedPrmopt === "default"
            ? defaultPromptWithText
            : presentText !== undefined
            ? presentText
            : textPresentPrompt?.includes("undefined")
            ? textPresentPrompt?.replace("undefined", "")
            : textPresentPrompt,
        promptWithOutText:
          selectedPrmopt === "default"
            ? defaultPromptWithOutText
            : notPresentText !== undefined
            ? notPresentText
            : textNotPresentPrompt?.includes("undefined")
            ? textNotPresentPrompt?.replace("undefined", "")
            : textNotPresentPrompt,
        promptWithTextProperties:
          selectedPrmopt === "default" ? null : promptWithTextProperties,
        promptWithOutTextProperties:
          selectedPrmopt === "default" ? null : promptWithOutTextProperties,
      };
      if (
        selectedRating?.some(
          (item) => item?.hasOwnProperty("error") && item?.error === true
        )
      ) {
        templateGridRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      console.log(selectedRating, "selectedRating");
      if (aiReview === true) {
        if (
          selectedRating?.some(
            (item) => item?.hasOwnProperty("error") && item?.error === true
          ) === false &&
          data?.title !== "" &&
          data?.templateIds?.length > 0 &&
          data?.locationIds?.length > 0 &&
          data?.ratingStar?.length > 0 &&
          selectedRating?.length > 0 &&
          selectedReviewOption?.length > 0
        ) {
          try {
            setUpdateLoading(true);
            const res = await api.patch(`/rule/${id}`, data);

            /*   if (res.status === 200) {
          setLoading(true);
          

          navigate("/user/rules");
          setIsLoading(false);
        } */
            if (res.status === 200) {
              toast.success(t("Rule_updated_successfully"));
              if (navigateBool !== undefined && navigateBool === true) {
                navigate("/user/rules");
              }

              setErrors("");
              //  setMessage(res.data.message);
              setUpdateLoading(false);
            }
          } catch (error) {
            setUpdateLoading(false);
            handleCloseUpdateModel();
            //  toast.error(error?.response?.data?.message);
            setErrors(error?.response?.data?.message);
            setErrorRules(error?.response?.data?.data);
            // toast.error("ratingStar with this locationIds already exist");
          }
        }
      } else {
        if (
          data.title !== "" &&
          //  data.templateIds.length > 0 &&
          data?.locationIds?.length > 0 &&
          data?.ratingStar?.length > 0 &&
          selectedRating.length > 0 &&
          selectedReviewOption?.length > 0
        ) {
          try {
            setUpdateLoading(true);
            const res = await api.patch(`/rule/${id}`, data);

            /*   if (res.status === 200) {
          setLoading(true);

          navigate("/user/rules");
          setIsLoading(false);
        } */
            if (res.status === 200) {
              toast.success("Rule updated successfully");
              if (navigateBool !== undefined && navigateBool === true) {
                navigate("/user/rules");
              }
              setErrors("");
              //  setMessage(res.data.message);
              setUpdateLoading(false);
            }

            setIsLoading(false);
          } catch (error) {
            setUpdateLoading(false);
            handleCloseUpdateModel();
            //  toast.error(error?.response?.data?.message);
            setErrors(error?.response?.data?.message);
            setErrorRules(error?.response?.data?.data);
            // toast.error("ratingStar with this locationIds already exist");
          }
        }
      }
    }
  };
  const handleReviewText = (value) => {
    setSelectedReviewOption(value);
  };

  const getAllTemplate = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/template?limit=500&page=1`);
      if (res.status === 200) {
        // setLoading(true);
        setAllTemplate(res?.data?.data);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const createRule = async () => {
    if (title === null || title === "") {
      setTitleError(true);
      nameGridRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      setTitleError(false);
    }
    if (templateRequired) {
      templateGridRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    /*  if (selectedTemplate.length < 1) {
      setTemplateRequired(true);
    } */
    if (selectedLocation.length < 1) {
      setLocationRequired(true);
      locationGridRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (selectedRating?.length === 0) {
      setRatingRequired(true);
      scrollToRatingGrid();
    }
    if (selectedReviewOption.length < 1) {
      setReviewRequired(true);
      scrollToRatingGrid();
    }

    let locationIds = [];
    selectedLocation?.map((location) => {
      locationIds.push(location?.id);
    });
    // ================================================================

    const promptWithTextProperties = {
      nameOfCustomer:
        firstNameChecked &&
        fullNameChecked === false &&
        excludeNameChecked === false
          ? "firstName"
          : firstNameChecked === false &&
            fullNameChecked &&
            excludeNameChecked === false
          ? "fullName"
          : "Guest",
      toneOfPrompt: formalChecked
        ? "formalTone"
        : friendlyChecked
        ? "friendlyTone"
        : "reviewTone",

      includeKeywords: tagsInclude,
      excludeKeywords: tagsExclude,
      // length: shortChecked ? "short" : detailedCheked ? "detailed" : "custom",
      responseLength: customLengthValue,
      signatureOfPrompt: yesChecked ? "includeSignature" : "excludeSignature",
      signatureText: yesChecked ? signatureValue : null,
      businessEmail: defaultEmailchecked ? null : emailValue,
      languageOfPrompt: userLanguageChecked
        ? "locationLanguage"
        : reviewLanguageChecked
        ? "reviewLanguage"
        : "multipleLanguage",
      languageName: moreLanguageChecked ? userLanguage : null,
      extraData: extraData,
      pronounOfPrompt: singleChecked
        ? "singularPronoun"
        : multiChecked
        ? "pluralPronoun"
        : null,
    };
    const promptWithOutTextProperties = {
      nameOfCustomer:
        firstNameCheckedTwo &&
        fullNameCheckedTwo === false &&
        excludeNameCheckedtwo === false
          ? "firstName"
          : firstNameCheckedTwo === false &&
            fullNameCheckedTwo &&
            excludeNameCheckedtwo === false
          ? "fullName"
          : "Guest",
      toneOfPrompt: formalCheckedTwo
        ? "formalTone"
        : friendlyCheckedTwo
        ? "friendlyTone"
        : "reviewTone",

      includeKeywords: tagsIncludeNotPresent,
      excludeKeywords: tagsExcludeNotPresent,
      /*  length: shortCheckedTwo
        ? "short"
        : detailedChekedTwo
        ? "detailed"
        : "custom", */
      responseLength: customLengthValueTwo,
      signatureOfPrompt: yesCheckedTwo
        ? "includeSignature"
        : "excludeSignature",
      signatureText: yesCheckedTwo ? signatureValue : null,
      businessEmail: defaultEmailcheckedTwo ? null : emailValueTwo,
      // languageOfPrompt: userLanguageCheckedTwo ? "userLanguage" : "more",
      // languageName: moreLanguageCheckedTwo ? userLanguageTwo : null,
      extraData: extraDataTwo,
      pronounOfPrompt: singleCheckedTwo
        ? "singularPronoun"
        : multiCheckedTwo
        ? "pluralPronoun"
        : null,
    };
    let templateIds = [];
    if (aiReview === true) {
      selectedRating?.map((item) => {
        item?.template?.map((itemTwo) => {
          let obj = {
            id: itemTwo?.id,
            ratingStar: item?.value,
          };
          templateIds?.push(obj);
        });
      });
    }

    const data = {
      title: title,
      templateIds: aiReview === true ? templateIds : null,
      locationIds: locationIds,
      ratingStar: selectedRating.map((rating) => rating?.value),
      reviewTextPresent: reviewPresent,
      reviewTextNotPresent: reviewNotPresent,
      isSupervised: aiReview,
      defaultPrompt: selectedPrmopt === "default" ? true : false,
      samePromptProperties: autoSelect,
      promptWithText:
        selectedPrmopt === "default"
          ? defaultPromptWithText
          : textPresentPrompt,
      promptWithOutText:
        selectedPrmopt === "default"
          ? defaultPromptWithOutText
          : textNotPresentPrompt,
      promptWithTextProperties:
        selectedPrmopt === "default" ? null : promptWithTextProperties,
      promptWithOutTextProperties:
        selectedPrmopt === "default" ? null : promptWithOutTextProperties,
    };

    if (
      selectedRating?.some(
        (item) => item?.hasOwnProperty("error") && item?.error === true
      )
    ) {
      templateGridRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    if (aiReview === true) {
      if (
        selectedRating?.some(
          (item) => item?.hasOwnProperty("error") && item?.error === true
        ) === false &&
        data?.title !== "" &&
        data?.title !== null &&
        data?.locationIds?.length > 0 &&
        data?.ratingStar?.length > 0 &&
        selectedRating?.length > 0 &&
        selectedReviewOption?.length > 0
      ) {
        try {
          setIsLoading(true);
          const res = await api.post(`/rule`, data);

          if (res.status === 200) {
            setIsLoading(false);
            setErrors("");
            //   setMessage(res.data.message);
            toast.success(t("Rule_added_successfully"));
            navigate("/user/rules");
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          setErrors(error?.response?.data?.message || "Something went wrong");
          setErrorRules(error?.response?.data?.data);
          //  toast.error(error?.response?.data?.message);
        }
      }
    } else {
      if (
        //  data?.templateIds !== null &&
        data?.title !== "" &&
        data?.title !== null &&
        data?.locationIds?.length > 0 &&
        data?.ratingStar?.length > 0 &&
        selectedRating?.length > 0 &&
        selectedReviewOption?.length > 0
      ) {
        try {
          setIsLoading(true);
          const res = await api.post(`/rule`, data);

          if (res.status === 200) {
            setIsLoading(false);
            setErrors("");
            //   setMessage(res.data.message);
            toast.success("Rule added successfully");
            navigate("/user/rules");
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          setErrors(error?.response?.data?.message || "Something went wrong");
          setErrorRules(error?.response?.data?.data);
          //  toast.error(error?.response?.data?.message);
        }
      }
    }
  };

  const handleAddTemplate = () => {
    navigate("/user/template");
  };
  const handleSuperviseTemplate = () => {
    setAIReview(true);
    setTemplateRequired(true);
    let arr = [...selectedRating];
    arr.map((item) => {
      item["error"] = true;
    });
    setSelectedRating(arr);
  };

  const handleUnsuperviseTemplate = () => {
    setAIReview(false);
    let arr = [...selectedRating];
    arr.map((item) => {
      item["template"] = [];
    });
    setSelectedRating(arr);
    setTemplateRequired(false);
  };
  const handleOpenPromptModel = () => {
    setOpenPrmoptModel(true);
  };
  const handleClosePromptModel = () => {
    setOpenPrmoptModel(false);
    handleReset();
  };

  const handleChangeTextPresentPromptProperties = (prompt, properties) => {
    let str = prompt;

    str = str?.replace("nameOfCustomer", properties?.nameOfCustomer);
    if (properties?.nameOfCustomer === "Guest") {
      str = str.replace(
        "Please address the customer by their name and provide a polite and constructive response",
        "Please provide a polite and constructive response"
      );
    }
    if (properties?.businessEmail !== null) {
      str = str?.replace("{{businessEmail}}", properties?.businessEmail);
    }
    str = str?.replace("languageOfPrompt", properties?.languageOfPrompt);
    str = str?.replace("toneOfPrompt", properties?.toneOfPrompt);
    //  str = str?.replace("toneOfPrompt", properties?.toneOfPrompt);
    str = str?.replace("signatureOfPrompt", properties?.signatureOfPrompt);

    if (properties?.pronounOfPrompt !== null) {
      str = str?.replace("pronounOfPrompt", properties?.pronounOfPrompt);
    } else {
      let splittedStr = str.split(".");
      let filteredStr = splittedStr?.filter(
        (text) => text.includes("pronounOfPrompt") === false
      );
      str = filteredStr.join(".");
    }
    if (properties?.extraData !== null && properties?.extraData !== "") {
      str = str?.replace(
        "{{-selectedExtras-}}",
        "Please consider this note in response:" + " " + properties?.extraData
      );
    }
    /*  if (properties?.phraseOfPrompt === null) {
      let splittedStr = str.split(".");
      let filteredStr = splittedStr?.filter(
        (text) => text.includes("{{-phraseOfPrompt-}} phrases like") === false
      );
      str = filteredStr.join(".");
    } else {
      str = str?.replace("phraseOfPrompt", properties?.phraseOfPrompt);
      str = str?.replace("{{-keywords-}}", properties?.keywords?.toString());
    } */

    return str;
  };

  const handleReset = (resetValue) => {
    if (
      touchedValue === false ||
      (resetValue !== undefined && resetValue === false)
    ) {
      if (id === undefined) {
        setTagsInclude([]);
        setTagsExclude([]);
        setSingleChecked(false);
        setSingleCheckedTwo(false);
        setMultiChecked(false);
        setMultiCheckedTwo(false);
        setSelectAllPresent(false);
        setSelectAllNotPresent(false);
        setTagsIncludeNotPresent([]);
        setTagsExcludeNotPresent([]);
        setFormalChecked(true);
        setFormalCheckedTwo(true);
        setReviewToneChecked(false);
        setReviewToneCheckedTwo(false);
        setFriendlyChecked(false);
        setFriendlyCheckedTwo(false);
        setIncludeChecked(false);
        setIncludeCheckedTwo(false);
        setExcludeChecked(false);
        setExcludeCheckedTwo(false);
        setYesChecked(false);
        setYesCheckedTwo(false);
        setNoChecked(true);
        setNoCheckedTwo(true);
        setMoreLanguageChecked(false);
        setMoreLanguageCheckedTwo(false);
        setUserLanguageChecked(false);
        setUserLanguageCheckedTwo(true);
        setUserLanguage("");
        setUserLanguageTwo("");
        setReviewLanguageChecked(true);
        setExtraData("");
        setExtraDataTwo("");
        setDisplayToast(false);
        setModelNotPresentPrompt(textNotPresentPrompt);
        setModelPresentPrompt(textPresentPrompt);
        setUserTranslationLanguage(i18nextLng);
        setUserTranslationLanguageTwo(i18nextLng);
        setFirstNameChecked(false);
        setFirstNameCheckedTwo(false);
        setFullNameChecked(true);
        setFullNameCheckedTwo(true);
        setExcludeNameChecked(false);
        setExcludeNameCheckedTwo(false);
        setEmailValidation(false);
        setEmailValidationTwo(false);
        setDefualtEmailChecked(true);
        setDefualtEmailCheckedTwo(true);
        setCustomEmailChecked(false);
        setCustomEmailCheckedTwo(false);
        setEmailValue(null);
        setEmailValueTwo(null);
        setSignatureValue(null);
        setSignatureValueTwo(null);
        setAutoSelect(true);
        setCustomLengthValueTwo(2);
        setCustomLengthValue(2);
      } else {
        setAutoSelect(dataToEdit?.samePromptProperties);
        if (dataToEdit?.defaultPrompt === false) {
          setSelectedPrompt("custom");
          let textPresentFormatedValue =
            handleChangeTextPresentPromptProperties(
              textPresentPrompt,
              dataToEdit?.promptWithTextProperties,
              true
            );
          let textNotPresentFormatedValue =
            handleChangeTextPresentPromptProperties(
              textNotPresentPrompt,
              dataToEdit?.promptWithOutTextProperties,
              false
            );
          setModelNotPresentPrompt(textNotPresentFormatedValue);
          setTextNotPresentPrompt(textNotPresentFormatedValue);
          setModelPresentPrompt(textPresentFormatedValue);
          setTextPresentPrompt(textPresentFormatedValue);
        } else {
          setSelectedPrompt("default");
        }

        if (dataToEdit?.promptWithTextProperties !== null) {
          setExtraData(dataToEdit?.promptWithTextProperties?.extraData);
          setTagsInclude(dataToEdit?.promptWithTextProperties?.includeKeywords);
          setTagsExclude(dataToEdit?.promptWithTextProperties?.excludeKeywords);
          if (dataToEdit?.promptWithTextProperties?.businessEmail !== null) {
            setDefualtEmailChecked(false);
            setCustomEmailChecked(true);
            setEmailValue(dataToEdit?.promptWithTextProperties?.businessEmail);
          } else {
            setDefualtEmailChecked(true);
            setCustomEmailChecked(false);
            setEmailValue(null);
          }
          if (dataToEdit?.promptWithText !== null) {
            setCustomLengthValue(
              dataToEdit?.promptWithTextProperties?.responseLength
            );
            if (
              dataToEdit?.promptWithTextProperties?.nameOfCustomer ===
              "fullName"
            ) {
              setFullNameChecked(true);
              setFirstNameChecked(false);
              setExcludeNameChecked(false);
            } else if (
              dataToEdit?.promptWithTextProperties?.nameOfCustomer ===
              "firstName"
            ) {
              setFullNameChecked(false);
              setFirstNameChecked(true);
              setExcludeNameChecked(false);
            } else if (
              dataToEdit?.promptWithTextProperties?.nameOfCustomer === "Guest"
            ) {
              setFullNameChecked(false);
              setFirstNameChecked(false);
              setExcludeNameChecked(true);
            }
          }
          if (
            dataToEdit?.promptWithTextProperties?.toneOfPrompt === "formalTone"
          ) {
            setFormalChecked(true);
            setFriendlyChecked(false);
            setReviewToneChecked(false);
          } else if (
            dataToEdit?.promptWithTextProperties?.toneOfPrompt ===
            "friendlyTone"
          ) {
            setFormalChecked(false);
            setFriendlyChecked(true);
            setReviewToneChecked(false);
          } else {
            setFormalChecked(false);
            setFriendlyChecked(false);
            setReviewToneChecked(true);
          }
          if (dataToEdit?.promptWithTextProperties?.pronounOfPrompt === null) {
            setMultiChecked(false);
            setSingleChecked(false);
          } else if (
            dataToEdit?.promptWithTextProperties?.pronounOfPrompt ===
            "singularPronoun"
          ) {
            setMultiChecked(false);
            setSingleChecked(true);
          } else {
            setMultiChecked(true);
            setSingleChecked(false);
          }
          setTagsInclude(dataToEdit?.promptWithTextProperties?.includeKeywords);
          setTagsExclude(dataToEdit?.promptWithTextProperties?.excludeKeywords);

          if (
            dataToEdit?.promptWithTextProperties?.signatureOfPrompt ===
            "includeSignature"
          ) {
            setYesChecked(true);
            setNoChecked(false);
            setSignatureValue(
              dataToEdit?.promptWithTextProperties?.signatureText
            );
          } else {
            setYesChecked(false);
            setNoChecked(true);
            setSignatureValue(null);
          }
          if (
            dataToEdit?.promptWithTextProperties?.languageOfPrompt ===
            "locationLanguage"
          ) {
            setUserLanguageChecked(true);
            setReviewLanguageChecked(false);
            setMoreLanguageChecked(false);
          } else if (
            dataToEdit?.promptWithTextProperties?.languageOfPrompt ===
            "reviewLanguage"
          ) {
            setUserLanguageChecked(false);
            setReviewLanguageChecked(true);
            setMoreLanguageChecked(false);
          } else {
            setUserLanguageChecked(false);
            setReviewLanguageChecked(false);
            setMoreLanguageChecked(true);
            setUserLanguage(dataToEdit?.promptWithTextProperties?.languageName);
          }
        }
        if (dataToEdit?.promptWithOutTextProperties !== null) {
          setCustomLengthValueTwo(
            dataToEdit?.promptWithOutTextProperties?.responseLength
          );
          setExtraDataTwo(dataToEdit?.promptWithOutTextProperties?.extraData);
          setTagsInclude(
            dataToEdit?.promptWithOutTextProperties?.includeKeywords
          );
          setTagsExclude(
            dataToEdit?.promptWithOutTextProperties?.excludeKeywords
          );

          if (
            dataToEdit?.promptWithOutTextProperties?.nameOfCustomer ===
            "fullName"
          ) {
            setFullNameCheckedTwo(true);
            setFirstNameCheckedTwo(false);
            setExcludeNameCheckedTwo(false);
          } else if (
            dataToEdit?.promptWithOutTextProperties?.nameOfCustomer ===
            "firstName"
          ) {
            setFullNameCheckedTwo(false);
            setFirstNameCheckedTwo(true);
            setExcludeNameCheckedTwo(false);
          } else {
            setFullNameCheckedTwo(false);
            setFirstNameCheckedTwo(false);
            setExcludeNameCheckedTwo(true);
          }

          if (dataToEdit?.promptWithOutTextProperties?.businessEmail !== null) {
            setDefualtEmailCheckedTwo(false);
            setCustomEmailCheckedTwo(true);
            setEmailValueTwo(
              dataToEdit?.promptWithOutTextProperties?.businessEmail
            );
          } else {
            setDefualtEmailCheckedTwo(true);
            setCustomEmailCheckedTwo(false);
            setEmailValueTwo(null);
          }
          if (
            dataToEdit?.promptWithOutTextProperties?.toneOfPrompt ===
            "formalTone"
          ) {
            setFormalCheckedTwo(true);
            setFriendlyCheckedTwo(false);
            setReviewToneCheckedTwo(false);
          } else if (
            dataToEdit?.promptWithOutTextProperties?.toneOfPrompt ===
            "friendlyTone"
          ) {
            setFormalCheckedTwo(false);
            setFriendlyCheckedTwo(true);
            setReviewToneCheckedTwo(false);
          } else {
            setFormalCheckedTwo(false);
            setFriendlyCheckedTwo(false);
            setReviewToneCheckedTwo(true);
          }
          setTagsIncludeNotPresent(
            dataToEdit?.promptWithOutTextProperties?.includeKeywords
          );
          setTagsExcludeNotPresent(
            dataToEdit?.promptWithOutTextProperties?.excludeKeywords
          );

          if (
            dataToEdit?.promptWithOutTextProperties?.signatureOfPrompt ===
            "includeSignature"
          ) {
            setYesCheckedTwo(true);
            setNoCheckedTwo(false);
            setSignatureValueTwo(
              dataToEdit?.promptWithOutTextProperties?.signatureText
            );
          } else {
            setYesCheckedTwo(false);
            setNoCheckedTwo(true);
            setSignatureValueTwo(null);
          }
          if (
            dataToEdit?.promptWithOutTextProperties?.pronounOfPrompt === null
          ) {
            setMultiCheckedTwo(false);
            setSingleCheckedTwo(false);
          } else if (
            dataToEdit?.promptWithOutTextProperties?.pronounOfPrompt ===
            "singularPronoun"
          ) {
            setMultiCheckedTwo(false);
            setSingleCheckedTwo(true);
          } else {
            setMultiCheckedTwo(true);
            setSingleCheckedTwo(false);
          }
          /*    if (
            dataToEdit?.promptWithOutTextProperties?.language === "userLanguage"
          ) {
            setUserLanguageCheckedTwo(true);
            //  setReviewLanguageChecked(false);
            setMoreLanguageCheckedTwo(false);
          } else if (
            dataToEdit?.promptWithOutTextProperties?.language ===
            "reviewLanguage"
          ) {
            setUserLanguageCheckedTwo(false);
            //  setReviewLanguageChecked(true);
            setMoreLanguageCheckedTwo(false);
          } else {
            setUserLanguageCheckedTwo(false);
            //  setReviewLanguageChecked(false);
            setMoreLanguageCheckedTwo(true);
            setUserLanguage(
              dataToEdit?.promptWithOutTextProperties?.languageName
            );
          } */
        }
      }
    }
  };

  const handleSavePrompt = () => {
    setTouchedValue(true);
    let tagsError = false;
    let tagsErrorTwo = false;
    let langError = false;
    let langErrorTwo = false;
    if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Present") ||
        selectedTab === 0) &&
      (excludeChecked || includeChecked) &&
      tagsInclude?.length === 0
    ) {
      setTagsRequired(true);
      setScrollStateTags(true);

      tagsError = true;
    } else if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Not Present") ||
        selectedTab === 1) &&
      (excludeCheckedTwo || includeCheckedTwo) &&
      tagsIncludeNotPresent?.length === 0
    ) {
      setTagsRequiredTwo(true);
      setScrollStateTagsTwo(true);

      tagsErrorTwo = true;
    } else {
      setTagsRequired(false);
      setScrollStateTags(false);
      setScrollStateTagsTwo(false);

      tagsError = false;
      setTagsRequiredTwo(false);
      tagsErrorTwo = false;
    }
    if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Present") ||
        selectedTab === 0) &&
      moreLanguageChecked &&
      userLanguage?.length === 0
    ) {
      setLanguageRequired(true);
      setScrollStateLang(true);

      langError = true;
    } else if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Not Present") ||
        selectedTab === 1) &&
      moreLanguageCheckedTwo &&
      userLanguageTwo?.length === 0
    ) {
      setLanguageRequiredTwo(true);
      setScrollStateLangTwo(true);
      langErrorTwo = true;
    } else {
      setLanguageRequiredTwo(false);
      setScrollStateLangTwo(false);
      langError = false;
      setLanguageRequired(false);
      langErrorTwo = false;
    }
    if (
      tagsError === false &&
      tagsErrorTwo === false &&
      langError === false &&
      langErrorTwo === false
    ) {
      setOpenPrmoptModel(false);
      setTextPresentPrompt(modelPresentPrompt);
      setTextNotPresentPrompt(modelNotPresentPrompt);

      if (id !== undefined) {
        updateRule(modelPresentPrompt, modelNotPresentPrompt, false);
      }
    }
  };

  const handleClickPrmoptSelection = (key) => {
    if (key === "default") {
      setTouchedValue(false);
      handleReset(false);
    }
    if (selectedPrmopt === "default" && key === "custom") {
      setOpenPrmoptModel(true);
    }
    setSelectedPrompt(key);
  };

  function handleDisplayNames(locationId) {
    let locationName = "";

    if (allLocations && allLocations?.results?.length > 0) {
      const location = allLocations?.results?.find(
        (item) => item?.id === locationId
      );
      locationName = location ? location.name : "";
    }

    return locationName;
  }
  function getLocation(locationId) {
    let locationName = "";

    if (allLocations && allLocations?.results?.length > 0) {
      const location = allLocations?.results?.find(
        (item) => item?.id === locationId
      );
      locationName = location ? location : "";
    }

    return locationName;
  }
  const handleOpenUpdateModel = () => {
    if (displayWarning) {
      setOpenUpdateModel(true);
    } else {
      setOpenUpdateModel(false);
      updateRule(undefined, undefined, true);
    }
  };
  const handleCloseUpdateModel = () => {
    setOpenUpdateModel(false);
  };

  const handleCloseEmailWaring = () => {
    setDisplayEmailWarning(false);
  };

  const handleOpenAddEmailModel = () => {
    setOpenAddEmailModel(true);
  };
  const handleCloseAddEmailModel = () => {
    setOpenAddEmailModel(false);
  };

  const handleOpenAddBulkEmailModel = () => {
    setOpenAddBulkEmailModel(true);
    // setOpenAddEmailModel(false);
  };
  const handleCloseAddBulkEmailModel = () => {
    setOpenAddBulkEmailModel(false);
  };

  const handleChangeTemplateForRating = (value, indexValue) => {
    let arr = [...selectedRating];
    if (value.length === 0) {
      arr[indexValue].template = [];
    } else {
      arr[indexValue].template = value;
    }
    setSelectedRating(arr);
    handleDisplayError(arr);
  };

  const handleDisplayError = (ratingArray) => {
    if (aiReview) {
      if (ratingArray?.length > 0) {
        const templatesFound = ratingArray?.filter(
          (item) => item?.template?.length > 0
        );
        if (templatesFound?.length === ratingArray?.length) {
          setTemplateRequired(false);
          let arr = [...ratingArray];
          arr.map((item) => {
            item["error"] = false;
          });
          setSelectedRating(arr);
        } else {
          setTemplateRequired(true);
          let arr = [...ratingArray];
          arr.map((item) => {
            if (item?.template?.length === 0) {
              item["error"] = true;
            } else {
              item["error"] = false;
            }
          });
          setSelectedRating(arr);
        }
      }
    } else {
      let arr = [...ratingArray];
      arr.map((item) => {
        item["template"] = [];
      });
      setSelectedRating(arr);
      setTemplateRequired(false);
    }
  };

  useEffect(() => {
    if (!!prefilled) {
      if (allLocations?.results?.length > 0) {
        const filteredLocations = allLocations.results.filter((loc) => {
          return (
            loc?.businessEmail?.trim() !== null &&
            loc?.businessEmail?.trim() !== undefined &&
            loc?.businessEmail?.trim() !== ""
          );
        });
        setSelectAll(true);
        setLocationRequired(false);
        setSelectedLocation(filteredLocations);
      }

      setReviewRequired(false);
      handleReviewText(state);

      setSelectAllRating(true);
      setRatingRequired(false);

      const matchingRatings = Ratings.map((rating) => ({
        ...rating,
        template: [], // Add an empty templates array
      }));
      setSelectedRating(matchingRatings);
    }
  }, [prefilled, allLocations, Ratings]);

  return (
    <>
      {openAddEmailModel && (
        <AddBusinessModel
          open={openAddEmailModel}
          handleCloseAddEmailModel={handleCloseAddEmailModel}
          handleOpenAddBulkEmailModel={handleOpenAddBulkEmailModel}
          setAllLocations={setAllLocations}
          setBusinessEmailLocations={setBusinessEmailLocations}
          setDisplayEmailWarning={setDisplayEmailWarning}
          setSelectAllLocations={setSelectAll}
          setSelectedLocation={setSelectedLocation}
          setLocationRequired={setLocationRequired}
          selectedLocation={selectedLocation}
        />
      )}
      {openAddBulkEmailModel && (
        <AddBulkEmailModel
          open={openAddBulkEmailModel}
          handleCloseAddEmailModel={handleCloseAddBulkEmailModel}
          handleOpenAddBulkEmailModel={handleOpenAddBulkEmailModel}
          setAllLocations={setAllLocations}
          setBusinessEmailLocations={setBusinessEmailLocations}
          setDisplayEmailWarning={setDisplayEmailWarning}
          setSelectAllLocations={setSelectAll}
          handleCloseModel={handleCloseAddEmailModel}
          setLocationsRequired={setLocationRequired}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="add-rule-box1">
            <TitleHeader
              title={t("AutoRule_Title")}
              //  subHeading={t("Rule_Edit")}
              subHeading={""}
              showButton={true}
              createButton={false}
              onClick={id !== undefined ? handleOpenUpdateModel : createRule}
              extraButton={true}
              extraButtonName={
                id !== undefined ? <>{t("Update")} </> : <>{t("Save")}</>
              }
              handleDefaultTemplate={
                id !== undefined ? handleOpenUpdateModel : createRule
              }
              locationPage={false}
              viewPermission={true}
              addPermission={true}
              disableButton={
                /*  titleError ||
                locationRequired ||
                reviewRequired ||
                ratingRequired ||
                (aiReview && templateRequired) || */
                updateLoading || isLoading
              }
              isLoading={isLoading || updateLoading}
            />
          </Box>
          {errors && (
            <Grid container className={classes.mainContainer}>
              <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                <ErrorOutlineIcon className={classes.errorIcon} />
              </Grid>
              <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {errorRules?.length > 0 ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {errorRules?.map((item, index) => (
                        <Typography key={index} className={classes.errorText}>
                          {"•" + " " + item}
                        </Typography>
                      ))}
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography className={classes.errorText}>
                        {"•" + " " + errors}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          <Paper
            borderRadius="12px"
            sx={{ paddingTop: "16px", paddingBottom: "16px" }}
          >
            {/*   <Box className={classes.addRuleMainBox}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {editData ? (
                  <>
                    <Box>
                      <Typography className={classes.titleRule}>
                        {" "}
                        {t("Title")} <span style={{ color: "#FC3652" }}>*</span>
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          // disabled={loading}
                          required
                          placeholder={t("Enter_Title")}
                          defaultValue={title}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              setEditData(false);
                            }
                          }}
                          error={titleError}
                          onChange={(event) => {
                            if (event.target.value.length === 0) {
                              setTitleError(true);
                            } else {
                              setTitleError(false);
                            }
                            setTitle(event.target.value);
                          }}
                          fullWidth
                          variant="outlined"
                        />

                        <IconButton
                          aria-label="done"
                          size="large"
                          onClick={() => {
                            setEditData(!editData);
                          }}
                          disabled={!title}
                        >
                          {" "}
                          <CheckIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid sx={{ display: "flex", alignItems: "center" }}>
                      <Typography className={classes.editTitle}>
                        {" "}
                        {title}
                      </Typography>
                      <CustomWidthTooltip title={t("Edit_Title")}>
                        <IconButton
                          aria-label="edit"
                          sx={{ fontSize: "14px" }}
                          onClick={() => {
                            setEditData(!editData);
                          }}
                        >
                          {" "}
                          <Edit />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Grid>
                  </>
                )}
              </Box>

              <LoadingButton
                name="Save"
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  titleError ||
                  locationRequired ||
                  reviewRequired ||
                  ratingRequired ||
                  (aiReview && templateRequired)
                }
                onClick={id !== undefined ? handleOpenUpdateModel : createRule}
                loading={isLoading}
              >
                {id !== undefined ? <>{t("Update")} </> : <>{t("Save")}</>}
              </LoadingButton>
            </Box> 

            <Box className={classes.divider}>
              <Divider />
            </Box>*/}

            {displayWarning && (
              <Box className={classes.toast}>
                <Box className={classes.toastBox}>
                  <Box className={classes.toastBoxBorder}></Box>
                  <Box className={classes.toastBoxContent}>
                    <Box className={classes.toastBoxText}>
                      <WarningAmberOutlined sx={{ color: "#FAB431" }} />
                      <Box sx={{ display: "flex" }}>
                        <Typography>{t("location_error_text")}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {businessEmailLocations?.length > 0 && displayEmailWarning && (
              <Box className={classes.toast}>
                <Box className={classes.toastBox}>
                  <Box className={classes.toastBoxBorder}></Box>
                  <Box className={classes.toastBoxContent}>
                    <Box className={classes.toastBoxText}>
                      <WarningAmberOutlined sx={{ color: "#FAB431" }} />
                      <Box>
                        <Typography>
                          {t("businessEmail_alert")}{" "}
                          {businessEmailLocations?.length > 2 ? (
                            <>
                              {businessEmailLocations
                                .slice(0, 2)
                                .map((item) => item?.internalName)
                                .join(", ")}{" "}
                              {t("promptText_custom_and")}{" "}
                              <span style={{ fontWeight: 700 }}>
                                +
                                {`${businessEmailLocations?.length - 2} ${
                                  businessEmailLocations?.length - 2 === 1
                                    ? t("otherText")
                                    : t("othersText")
                                }`}
                              </span>
                            </>
                          ) : (
                            businessEmailLocations
                              .map((item) => item?.internalName)
                              .join(", ")
                          )}
                          .
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex" }}>
                        <CommonButton
                          label={t("Ignore")}
                          customHeight={true}
                          displayWhite="true"
                          onSubmit={handleCloseEmailWaring}
                        />
                        <CommonButton
                          label={t("Add")}
                          onSubmit={handleOpenAddEmailModel}
                          customHeight={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            <Box className={classes.addRuleLocation}>
              <Typography className={classes.headingTypoStyle}>
                {t("Title")}
              </Typography>
            </Box>
            <Box className={classes.addRuleLocationTitle} ref={nameGridRef}>
              <Paper sx={{ padding: "1rem" }}>
                <Typography sx={{ padding: "8px 0" }}>
                  {t("title_name")}
                </Typography>

                <TextField
                  // disabled={loading}
                  required
                  placeholder={t("enter_title_name")}
                  defaultValue={title}
                  error={titleError}
                  onChange={(event) => {
                    if (event.target.value.length === 0) {
                      setTitleError(true);
                    } else {
                      setTitleError(false);
                    }
                    setTitle(event.target.value);
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Paper>
            </Box>
            <Box className={classes.divider}>
              <Divider />
            </Box>

            <Box className={classes.addRuleLocation} ref={locationGridRef}>
              <Typography className={classes.headingTypoStyle}>
                {t("Locations")}
              </Typography>
            </Box>
            <Box className={classes.addRuleLocationTitle}>
              <Paper sx={{ padding: "1rem" }}>
                <Typography sx={{ padding: "8px 0" }}>
                  {t("Location_title")}
                </Typography>

                {id === undefined ? (
                  <Autocomplete
                    multiple
                    error={locationRequired}
                    disablePortal
                    id="combo-box-demo"
                    disableCloseOnSelect
                    options={
                      allLocations?.results?.length > 0
                        ? allLocations?.results
                        : []
                    }
                    //  disabled={disableLocations}
                    value={selectedLocation}
                    onChange={(event, value) => {
                      const filtered = allLocations?.results?.filter(
                        (item, index) =>
                          item.businessEmail !== null &&
                          item.businessEmail !== ""
                      );
                      // Check if 'All' option is clicked
                      if (value?.length > 0) {
                        setLocationRequired(false);
                      } else {
                        setLocationRequired(true);
                      }

                      if (value.find((option) => option.id === 0)) {
                        // Check if all options are selected
                        if (filtered.length === selectedLocation?.length) {
                          setSelectedLocation([]);
                          setSelectAll(false);
                        } else {
                          setSelectAll(true);
                          setSelectedLocation(filtered);

                          const defaultlocation = filtered?.find(
                            (item, index) => item.defaultLocation !== null
                          );
                          if (
                            defaultlocation !== null &&
                            defaultlocation !== undefined
                          ) {
                            setDefaultLocation(defaultlocation);
                          } else {
                            setDefaultLocation(filtered[0]);
                          }
                          //  setLocationRequired(false);
                        }
                      } else {
                        const filteredAllLoc = allLocations?.results?.filter(
                          (item, index) =>
                            item.businessEmail !== null &&
                            item.businessEmail !== ""
                        );
                        const filtered = value?.filter(
                          (item, index) =>
                            item.businessEmail !== null &&
                            item.businessEmail !== ""
                        );
                        setSelectedLocation(filtered);

                        const defaultlocation = value?.find(
                          (item, index) => item.defaultLocation !== null
                        );
                        if (
                          defaultlocation !== null &&
                          defaultlocation !== undefined
                        ) {
                          setDefaultLocation(defaultlocation);
                        } else {
                          setDefaultLocation(value[0]);
                        }
                        if (value?.length === filteredAllLoc?.length) {
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                        }
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (allLocations?.results?.length > 0) {
                        return [
                          { id: 0, internalName: "Select All" },
                          ...filtered,
                        ];
                      } else {
                        return [];
                      }
                    }}
                    defaultValue={selectedLocation}
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          " " +
                          "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : ""
                    }
                    sx={{ minWidth: 300 }}
                    /*   getOptionDisabled={(option) => {
                      if (option?.id === 0) {
                        return false;
                      }
                      if (
                        businessEmailLocations?.find(
                          (item) => item?.id === option?.id
                        )
                      ) {
                        return true;
                      }

                      return false;
                    }} */
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          disabled={
                            businessEmailLocations?.find(
                              (item) => item?.id === option?.id
                            ) !== undefined
                          }
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          id="select-all-checkbox"
                          checked={
                            (selectAll && option.id === 0) ||
                            businessEmailLocations?.find(
                              (item) => item?.id === option?.id
                            ) === false ||
                            selected
                          }
                        />

                        <Grid
                          container
                          style={{
                            justifyContent: "space-between",
                            marginTop: businessEmailLocations?.find(
                              (item, index) => item.id === option?.id
                            )
                              ? "6px"
                              : "0px",
                          }}
                        >
                          <Grid item xs={8} sm={8} md={8} lg={8}>
                            <span
                              style={{
                                textAlign: "start",
                                color: businessEmailLocations?.find(
                                  (item, index) => item.id === option?.id
                                )
                                  ? "#BBBDC1"
                                  : "#1B2430",
                              }}
                            >
                              {`${option?.internalName} ${
                                option?.addressLine1 !== "" &&
                                option?.addressLine1 !== null &&
                                option?.addressLine1 !== undefined
                                  ? "(" + option?.addressLine1 + "," + " "
                                  : ""
                              } ${option?.city ? option?.city + ")" : ""}`}
                            </span>
                          </Grid>
                          {businessEmailLocations?.find(
                            (item, index) => item.id === option?.id
                          ) && (
                            <Grid
                              item={3}
                              sm={3}
                              md={3}
                              lg={3}
                              sx={{ textAlign: "end" }}
                            >
                              <span
                                style={{
                                  textAlign: "center",
                                  height: "80px",
                                  // marginLeft: "8px",
                                  // marginRight: "18px",
                                  background: "#FFE3E3",
                                  padding: "3px 6px 3px 6px",
                                  borderRadius: "800px",
                                  fontSize: "10px",

                                  color: "#FF1616",
                                }}
                              >{`${t("Business email missing")}`}</span>
                            </Grid>
                          )}
                          {businessEmailLocations?.find(
                            (item, index) => item.id === option?.id
                          ) && (
                            <Grid
                              item={1}
                              sm={1}
                              md={1}
                              lg={1}
                              sx={{ textAlign: "center" }}
                            >
                              <BootstrapTooltip title={t("add_business_email")}>
                                <AddCircleIcon
                                  onClick={() => handleOpenAddEmailModel()}
                                  sx={{ color: "#06BDFF", cursor: "pointer" }}
                                />
                              </BootstrapTooltip>
                            </Grid>
                          )}
                        </Grid>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        name="language"
                        {...params}
                        error={locationRequired}
                        placeholder={t("Select_Location")}
                        //  defaultValue={{ selectedLocation }}
                      />
                    )}
                  ></Autocomplete>
                ) : (
                  <Autocomplete
                    multiple
                    error={locationRequired}
                    disablePortal
                    clearIcon={<Box sx={{ display: "none" }}></Box>}
                    id="combo-box-demo"
                    disableCloseOnSelect
                    options={locationsToEdit?.length > 0 ? locationsToEdit : []}
                    // disabled={disableLocations}
                    value={selectedLocation}
                    onChange={(event, value) => {
                      // Check if 'All' option is clicked
                      if (value?.length > 0) {
                        setLocationRequired(false);
                      } else {
                        setLocationRequired(true);
                      }

                      if (value.find((option) => option.id === 0)) {
                        // Check if all options are selected
                        let arr = [...alreadySelectedLocation];

                        const results = locationsToEdit.filter(
                          ({ id: id1 }) =>
                            !alreadySelectedLocation?.some(
                              ({ id: id2 }) => id2 === id1
                            )
                        );

                        const concatArr = results.concat(arr);

                        if (concatArr?.length !== selectedLocation?.length) {
                          setSelectedLocation(concatArr);
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                          setSelectedLocation(arr);
                          const defaultlocation = locationsToEdit?.find(
                            (item, index) => item.defaultLocation !== null
                          );
                          if (
                            defaultlocation !== null &&
                            defaultlocation !== undefined
                          ) {
                            setDefaultLocation(defaultlocation);
                          } else {
                            setDefaultLocation(locationsToEdit[0]);
                          }
                          setLocationRequired(false);
                        }
                      } else {
                        let arr = [...selectedLocation];
                        let newSelectedArr = [];
                        let addedIds = new Set();

                        value?.forEach((item) => {
                          let found = false;

                          arr.forEach((itemTwo) => {
                            if (item?.id === itemTwo?.id) {
                              if (!addedIds.has(itemTwo?.id)) {
                                newSelectedArr.push(itemTwo);
                                addedIds.add(itemTwo?.id);
                              }
                              found = true;
                            }
                          });

                          if (!found) {
                            const findItem = allLocations?.results?.find(
                              (loc) => loc?.id === item?.id
                            );

                            if (
                              findItem !== undefined &&
                              findItem?.businessEmail !== null &&
                              findItem?.businessEmail !== ""
                            ) {
                              if (!addedIds.has(item?.id)) {
                                newSelectedArr.push(item);
                                addedIds.add(item?.id);
                              }
                            }
                          }
                        });

                        setSelectedLocation(newSelectedArr);
                        const defaultlocation = newSelectedArr?.find(
                          (item, index) => item.defaultLocation !== null
                        );
                        if (
                          defaultlocation !== null &&
                          defaultlocation !== undefined
                        ) {
                          setDefaultLocation(defaultlocation);
                        } else {
                          setDefaultLocation(newSelectedArr[0]);
                        }
                        if (
                          newSelectedArr?.length === locationsToEdit?.length
                        ) {
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                        }
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (allLocations?.results?.length > 0) {
                        return [
                          { id: 0, internalName: "Select All" },
                          ...filtered,
                        ];
                      } else {
                        return [];
                      }
                    }}
                    defaultValue={selectedLocation}
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          " " +
                          "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : ""
                    }
                    getOptionDisabled={(option) => {
                      if (option?.id === 0) {
                        return false;
                      }

                      if (
                        allLocations?.results?.find(
                          (item) => item?.id === option?.id
                        )
                      ) {
                        return false;
                      }

                      return true;
                    }}
                    sx={{ minWidth: 300 }}
                    // value={locationId ? locationId : []}
                    // onChange={(e, value) => {
                    //   setLocationRequired(false);

                    //   setSelectedLocation(value);
                    // }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          style={{
                            background: "#EBEBEC",
                            border:
                              businessEmailLocations?.find(
                                (item) => item?.id === option?.id
                              ) === undefined
                                ? "none"
                                : "1px solid #FFA2A2",
                            borderRadius: "800px",
                          }}
                          label={
                            option?.internalName
                              ? option?.internalName +
                                " " +
                                "(" +
                                (option?.addressLine1 !== "" &&
                                option?.addressLine1 !== null &&
                                option?.addressLine1 !== undefined
                                  ? option?.addressLine1 + "," + " "
                                  : "") +
                                (option?.city ? option?.city : "") +
                                ")"
                              : ""
                          }
                          {...getTagProps({ index })}
                          disabled={
                            allLocations?.results?.find(
                              (item) => item?.id === option?.id
                            )
                              ? false
                              : true
                          }
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          id="select-all-checkbox"
                          checked={selectAll || selected}
                          disabled={
                            option.id === 0 ||
                            (allLocations?.results?.find(
                              (item) => item?.id === option?.id
                            ) &&
                              businessEmailLocations?.find(
                                (item) => item?.id === option?.id
                              ) === undefined)
                              ? false
                              : true
                          }
                          // checked={selected}
                        />
                        <Grid
                          container
                          style={{
                            justifyContent: "space-between",
                            marginTop: businessEmailLocations?.find(
                              (item, index) => item.id === option?.id
                            )
                              ? "6px"
                              : "0px",
                          }}
                        >
                          <Grid item xs={8} sm={8} md={8} lg={8}>
                            <span
                              style={{
                                textAlign: "start",
                                color: businessEmailLocations?.find(
                                  (item, index) => item.id === option?.id
                                )
                                  ? "#BBBDC1"
                                  : "#1B2430",
                              }}
                            >
                              {`${option?.internalName} ${
                                option?.addressLine1 !== "" &&
                                option?.addressLine1 !== null &&
                                option?.addressLine1 !== undefined
                                  ? "(" + option?.addressLine1 + "," + " "
                                  : ""
                              } ${option?.city ? option?.city + ")" : ""}`}
                            </span>
                          </Grid>
                          {businessEmailLocations?.find(
                            (item, index) => item.id === option?.id
                          ) && (
                            <Grid
                              item={3}
                              sm={3}
                              md={3}
                              lg={3}
                              sx={{ textAlign: "end" }}
                            >
                              <span
                                style={{
                                  textAlign: "center",
                                  height: "80px",
                                  // marginLeft: "8px",
                                  // marginRight: "18px",
                                  background: "#FFE3E3",
                                  padding: "3px 6px 3px 6px",
                                  borderRadius: "800px",
                                  fontSize: "10px",

                                  color: "#FF1616",
                                }}
                              >{`${t("Business email missing")}`}</span>
                            </Grid>
                          )}
                          {businessEmailLocations?.find(
                            (item, index) => item.id === option?.id
                          ) && (
                            <Grid
                              item={1}
                              sm={1}
                              md={1}
                              lg={1}
                              sx={{ textAlign: "center" }}
                            >
                              <BootstrapTooltip title={t("add_business_email")}>
                                <AddCircleIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpenAddEmailModel();
                                  }}
                                  sx={{ color: "#06BDFF", cursor: "pointer" }}
                                />
                              </BootstrapTooltip>
                            </Grid>
                          )}
                        </Grid>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        name="language"
                        {...params}
                        error={locationRequired}
                        placeholder={t("Select_Location")}
                        //  defaultValue={{ selectedLocation }}
                      />
                    )}
                  ></Autocomplete>
                )}
              </Paper>
            </Box>
            <Box className={classes.divider}>
              <Divider />
            </Box>

            <Box className={classes.addRuleLocation}>
              <Typography className={classes.headingTypoStyle}>
                {t("AI_Review_Response")}
              </Typography>
            </Box>
            <Box className={classes.addRuleLocationTitle}>
              <Paper sx={{ padding: "1rem" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={6} lg={6}>
                    <Typography sx={{ paddingTop: "30px" }}>
                      {t("Review_subhead")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={6} lg={6}>
                    <>
                      <Grid container spacing={2} sx={{ paddingTop: "8px" }}>
                        <BootstrapTooltip title={t("Unsupervised_text")}>
                          <Grid
                            item
                            xs={5.5}
                            sm={5.5}
                            md={5.5}
                            lg={5.5}
                            sx={{
                              backgroundColor:
                                aiReview === false ? "#E0F7FF" : "white",
                              border:
                                aiReview === false
                                  ? "1px solid #06BDFF"
                                  : "1px solid #E0E0E0",
                              borderRadius: "8px",
                            }}
                            onClick={() => handleUnsuperviseTemplate()}
                          >
                            <Box className={classes.skullBox}>
                              <img src={Skull} alt="Skull Icon" />
                            </Box>
                            <Typography className={classes.unsuperText}>
                              {t("Unsupervised")}
                            </Typography>
                          </Grid>
                        </BootstrapTooltip>

                        <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
                        <BootstrapTooltip
                          title={
                            allTemplate?.results?.length === 0
                              ? t("tooltipTextRule")
                              : t("Supervised_text")
                          }
                        >
                          <Grid
                            item
                            xs={5.5}
                            sm={5.5}
                            md={5.5}
                            lg={5.5}
                            sx={{
                              backgroundColor:
                                allTemplate?.results?.length === 0
                                  ? "#F6F6F7"
                                  : aiReview === true
                                  ? "#E0F7FF"
                                  : "#FFFF",
                              border:
                                aiReview === true
                                  ? "1px solid #06BDFF"
                                  : "1px solid #E0E0E0",
                              borderRadius: "8px",
                            }}
                            onClick={() => {
                              if (allTemplate?.results?.length > 0) {
                                handleSuperviseTemplate();
                              }
                            }}
                          >
                            <Box
                              sx={{
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                                paddingY: "8px",
                                height: "40px",
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  filter:
                                    allTemplate?.results?.length === 0
                                      ? "blur(2px)"
                                      : "none",
                                }}
                                src={Person}
                                alt="Person Icon"
                              />
                            </Box>
                            <Typography
                              sx={{
                                fontWeight: "500",
                                cursor: "pointer",
                                fontSize: "1.125rem",
                                justifyContent: "center",
                                display: "flex",
                                paddingBottom: "8px",
                                color:
                                  allTemplate?.results?.length === 0
                                    ? "gray"
                                    : "black",
                              }}
                            >
                              {t("Supervised")}
                            </Typography>
                          </Grid>
                        </BootstrapTooltip>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
            <Box className={classes.divider}>
              <Divider />
            </Box>

            <Box className={classes.addRuleLocation}>
              <Typography className={classes.headingTypoStyle}>
                {t("Conditions")}
              </Typography>
            </Box>
            <Box
              className={classes.Condition}
              borderColor="primary.main"
              ref={ratingGridRef}
            >
              <Typography variant="h6" marginBottom="2rem">
                {/* {t("Contition_1")} */}
              </Typography>

              <Paper padding="3rem">
                <Grid
                  className={classes.Rating}
                  // sx={{ marginBottom: "1rem" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={6}
                  background="blue"
                >
                  <Grid item md={2}>
                    <Box marginLeft="3rem">
                      <Typography>{t("Rating")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={8}>
                    <Box>
                      <Autocomplete
                        multiple
                        error={ratingRequired}
                        onChange={(e, value) => {
                          if (value?.length > 0) {
                            if (value.find((option) => option.id === 0)) {
                              if (Ratings?.length === selectedRating?.length) {
                                setSelectedRating([]);
                                setSelectAllRating(false);
                                handleDisplayError([]);
                              } else {
                                setSelectAllRating(true);
                                let arr = [...Ratings];
                                arr?.map((item) => {
                                  item["template"] =
                                    item?.template?.length > 0
                                      ? item?.template
                                      : [];
                                });
                                setSelectedRating(arr);
                                handleDisplayError(arr);
                              }
                            } else {
                              let arr = [...value];
                              arr?.map((item) => {
                                item["template"] =
                                  item?.template?.length > 0
                                    ? item?.template
                                    : [];
                              });
                              handleDisplayError(arr);
                              setSelectedRating(arr);
                              if (value?.length === Ratings?.length) {
                                setSelectAllRating(true);
                              } else {
                                setSelectAllRating(false);
                              }
                            }
                            setRatingRequired(false);
                          } else {
                            setRatingRequired(false);
                            setSelectedRating([]);
                            setSelectAllRating(false);
                          }
                        }}
                        disablePortal
                        disableCloseOnSelect
                        id="combo-box-demo"
                        getOptionLabel={(option) => t(option?.name)}
                        options={Ratings}
                        sx={{ width: "100%" }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        value={selectedRating}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected || selectAllRating}
                            />
                            {t(option?.name)}
                          </li>
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          if (Ratings?.length > 0) {
                            return [{ id: 0, name: "Select All" }, ...filtered];
                          } else {
                            return [];
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={ratingRequired}
                            placeholder={t("Select_Rating")}
                            //  defaultValue={{ selectedRating }}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid it md={2}>
                    {/* <Box>
                    <Typography>Rating text is</Typography>
                  </Box> */}
                  </Grid>
                  <Grid item md={2}>
                    <Box className={classes.Reviews}>
                      <Typography>{t("Review_text")}</Typography>
                    </Box>
                  </Grid>

                  <Grid item md={8}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Autocomplete
                        multiple
                        // error={locationRequired}
                        disablePortal
                        id="combo-box-demo"
                        disableCloseOnSelect
                        options={state}
                        value={selectedReviewOption}
                        onChange={(e, valueNew) => {
                          setReviewRequired(false);
                          handleReviewText(valueNew);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.label === value?.label
                        }
                        getOptionLabel={(option) => option.label}
                        sx={{ minWidth: 300 }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              // checked={selected}
                            />
                            {option?.label || ""}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            name="language"
                            {...params}
                            error={reviewRequired}
                            placeholder={t("Select_Review_Text")}
                          />
                        )}
                      ></Autocomplete>
                      {/*  <Autocomplete
                        multiple
                        disablePortal

                        value={selectedReviewOption}
                        disableCloseOnSelect
                        id="combo-box-demo"
                        onChange={(e, valueNew) => {
                          setReviewRequired(false);
                          handleReviewText(valueNew);
                        }}
                        options={state}
                        // isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              // onChange={(e) => {}}


                            />
                            {option.label}
                          </li>
                        )}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={reviewRequired}
                            placeholder={t("Select_Review_Text")}
                          />
                        )}
                      /> */}
                    </Box>
                  </Grid>
                  <Grid it md={2}>
                    {/* <Box>
                    <Typography>Met all the conditions</Typography>{" "}
                  </Box> */}
                  </Grid>
                </Grid>
              </Paper>
            </Box>

            {aiReview === true && selectedRating?.length > 0 ? (
              <>
                <Box className={classes.divider} ref={templateGridRef}>
                  <Divider />
                </Box>

                <Box className={classes.addRuleLocation}>
                  <Typography className={classes.headingTypoStyle}>
                    {t("Select_templates_for_stars")}
                  </Typography>
                </Box>
                <Box className={classes.addRuleLocationTitle}>
                  <>
                    {/* <Box>
                      <Typography>{t("Auto_Reply")}</Typography>
                    </Box> */}
                    <Paper padding="1rem">
                      {selectedRating
                        ?.sort((a, b) => a.id - b.id)
                        .map((starItem, indexValue) => (
                          <Box className={classes.Auto_Reply_templates}>
                            <Grid item xs={2} md={2} sm={2} lg={2}>
                              <Typography>{t(starItem?.name)}</Typography>
                            </Grid>
                            <Grid item xs={10} md={10} sm={10} lg={10}>
                              <Box>
                                {allTemplate?.results?.length > 0 ? (
                                  <Autocomplete
                                    //  error={templateRequired}
                                    multiple
                                    disablePortal
                                    id="combo-box-demo"
                                    options={allTemplate?.results}
                                    disableCloseOnSelect
                                    onChange={(e, value) => {
                                      // debugger
                                      if (
                                        value.find((option) => option.id === 0)
                                      ) {
                                        if (
                                          allTemplate?.results?.length ===
                                          starItem?.template?.length
                                        ) {
                                          handleChangeTemplateForRating(
                                            [],
                                            indexValue
                                          );
                                          setTemplateRequired(true);
                                        } else {
                                          handleChangeTemplateForRating(
                                            allTemplate?.results,
                                            indexValue
                                          );
                                          setTemplateRequired(false);
                                        }
                                      } else {
                                        setTemplateRequired(false);
                                        handleChangeTemplateForRating(
                                          value,
                                          indexValue
                                        );
                                      }
                                      // handleChangeTemplate(value);
                                      //  setSelectedTemplate(value);
                                    }}
                                    getOptionLabel={(option) => option?.title}
                                    value={starItem?.template}
                                    /*   defaultValue={
                                      selectedTemplate && selectedTemplate
                                    } */
                                    sx={{ width: "100%" }}
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => {
                                      let checked =
                                        allTemplate?.results?.length ===
                                        starItem?.template?.length;
                                      return (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={checked || selected}
                                          />
                                          {option?.title}
                                        </li>
                                      );
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);
                                      if (allTemplate?.results?.length > 0) {
                                        return [
                                          { id: 0, title: "Select All" },
                                          ...filtered,
                                        ];
                                      } else {
                                        return [];
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        error={
                                          starItem.hasOwnProperty("error") &&
                                          starItem?.error === true
                                        }
                                        placeholder={t("Select_Template")}
                                      />
                                    )}
                                  />
                                ) : (
                                  <Typography>
                                    No template found. Click
                                    <Link
                                      sx={{
                                        cursor: "pointer",
                                        color: " #0638C1",
                                      }}
                                      onClick={() => handleAddTemplate()}
                                    >
                                      {" "}
                                      here{" "}
                                    </Link>
                                    to add.
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                          </Box>
                        ))}
                    </Paper>
                  </>
                </Box>
              </>
            ) : (
              <></>
            )}

            <Box className={classes.divider}>
              <Divider />
            </Box>

            {aiReview === false &&
              selectedReviewOption?.length > 0 &&
              selectedLocation?.length > 0 && (
                <>
                  <Box className={classes.addRuleLocation}>
                    <Typography className={classes.headingTypoStyle}>
                      {t("Review_Reply_Prompt")}
                    </Typography>
                  </Box>

                  <Box className={classes.addRuleLocationTitle}>
                    <Paper sx={{ padding: "16px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={6} lg={6}>
                          <Typography sx={{ paddingTop: "30px" }}>
                            {t("select_prompt_option")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={6} lg={6}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ paddingTop: "8px" }}
                          >
                            <Grid
                              item
                              xs={5.5}
                              sm={5.5}
                              md={5.5}
                              lg={5.5}
                              sx={{
                                backgroundColor:
                                  selectedPrmopt === "default"
                                    ? "#E0F7FF"
                                    : "white",
                                border:
                                  selectedPrmopt === "default"
                                    ? "1px solid #06BDFF"
                                    : "1px solid #E0E0E0",
                                borderRadius: "8px",
                                height: "72px",
                                alignItems: "center",
                                display: "flex",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                              onClick={() =>
                                handleClickPrmoptSelection("default")
                              }
                            >
                              <Typography
                                className={classes.unsuperTextDefault}
                              >
                                {t("defualt_prompt")}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={0.5}
                              sm={0.5}
                              md={0.5}
                              lg={0.5}
                            ></Grid>
                            <Grid
                              item
                              xs={5.5}
                              sm={5.5}
                              md={5.5}
                              lg={5.5}
                              sx={{
                                backgroundColor:
                                  selectedPrmopt === "custom"
                                    ? "#E0F7FF"
                                    : "#FFFF",
                                border:
                                  selectedPrmopt === "custom"
                                    ? "1px solid #06BDFF"
                                    : "1px solid #E0E0E0",
                                borderRadius: "8px",
                                height: "72px",
                                alignItems: "center",
                                display: "flex",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                              onClick={() => {
                                handleClickPrmoptSelection("custom");
                              }}
                            >
                              <Typography
                                className={classes.unsuperTextDefault}
                              >
                                {t("Customised_prompt")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {selectedPrmopt === "custom" &&
                          (selectedReviewOption?.length === 2 ||
                            selectedReviewOption?.length === 1) && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={11.8}
                              lg={11.8}
                              sx={{ textAlign: "right" }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  lineHeight: "16px",
                                  color: "#06BDFF",
                                  cursor: "pointer",
                                  paddingTop: "5px",
                                  textAlign: "right",
                                  display: "flex",
                                  fontWeight: 700,
                                  justifyContent: "end",
                                  //  marginTop: "16px",
                                }}
                                onClick={handleOpenPromptModel}
                              >
                                {t("Edit_Prompt")}
                                {/*  <EditIcon sx={{ marginLeft: "3px" }} /> */}
                              </Typography>
                            </Grid>
                          )}
                      </Grid>
                    </Paper>
                  </Box>
                </>
              )}
          </Paper>
        </>
      )}
      <PostAIModal
        onCancel={handleClosePromptModel}
        openDefaultModel={openPrmoptModel}
        textPresentPrompt={textPresentPrompt}
        setTextPresentPrompt={setTextPresentPrompt}
        tagsInclude={tagsInclude}
        setTagsInclude={setTagsInclude}
        tagsExclude={tagsExclude}
        setTagsExclude={setTagsExclude}
        textNotPresentPrompt={textNotPresentPrompt}
        setTextNotPresentPrompt={setTextNotPresentPrompt}
        tagsIncludeNotPresent={tagsIncludeNotPresent}
        setTagsIncludeNotPresent={setTagsIncludeNotPresent}
        tagsExcludeNotPresent={tagsExcludeNotPresent}
        setTagsExcludeNotPresent={setTagsExcludeNotPresent}
        selectedReviewOption={selectedReviewOption}
        formalChecked={formalChecked}
        setFormalChecked={setFormalChecked}
        reviewToneChecked={reviewToneChecked}
        setReviewToneChecked={setReviewToneChecked}
        friendlyChecked={friendlyChecked}
        setFriendlyChecked={setFriendlyChecked}
        includeChecked={includeChecked}
        setIncludeChecked={setIncludeChecked}
        excludeChecked={excludeChecked}
        setExcludeChecked={setExcludeChecked}
        yesChecked={yesChecked}
        setYesChecked={setYesChecked}
        noChecked={noChecked}
        setNoChecked={setNoChecked}
        moreLanguageChecked={moreLanguageChecked}
        setMoreLanguageChecked={setMoreLanguageChecked}
        userLanguageChecked={userLanguageChecked}
        setUserLanguageChecked={setUserLanguageChecked}
        userLanguage={userLanguage}
        setUserLanguage={setUserLanguage}
        reviewLanguageChecked={reviewLanguageChecked}
        setReviewLanguageChecked={setReviewLanguageChecked}
        extraData={extraData}
        setExtraData={setExtraData}
        formalCheckedTwo={formalCheckedTwo}
        setFormalCheckedTwo={setFormalCheckedTwo}
        reviewToneCheckedTwo={reviewToneCheckedTwo}
        setReviewToneCheckedTwo={setReviewToneCheckedTwo}
        friendlyCheckedTwo={friendlyCheckedTwo}
        setFriendlyCheckedTwo={setFriendlyCheckedTwo}
        includeCheckedTwo={includeCheckedTwo}
        setIncludeCheckedTwo={setIncludeCheckedTwo}
        excludeCheckedTwo={excludeCheckedTwo}
        setExcludeCheckedTwo={setExcludeCheckedTwo}
        yesCheckedTwo={yesCheckedTwo}
        setYesCheckedTwo={setYesCheckedTwo}
        noCheckedTwo={noCheckedTwo}
        setNoCheckedTwo={setNoCheckedTwo}
        moreLanguageCheckedTwo={moreLanguageCheckedTwo}
        setMoreLanguageCheckedTwo={setMoreLanguageCheckedTwo}
        userLanguageCheckedTwo={userLanguageCheckedTwo}
        setUserLanguageCheckedTwo={setUserLanguageCheckedTwo}
        userLanguageTwo={userLanguageTwo}
        setUserLanguageTwo={setUserLanguageTwo}
        extraDataTwo={extraDataTwo}
        setExtraDataTwo={setExtraDataTwo}
        handleSavePrompt={handleSavePrompt}
        Ratings={selectedRating}
        selectedLocation={selectedLocation}
        setDisplayToast={setDisplayToast}
        displayToast={displayToast}
        selectedRating={selectedRating}
        modelPresentPrompt={modelPresentPrompt}
        setModelPresentPrompt={setModelPresentPrompt}
        modelNotPresentPrompt={modelNotPresentPrompt}
        setModelNotPresentPrompt={setModelNotPresentPrompt}
        userTranslationLanguage={userTranslationLanguage}
        setUserTranslationLanguage={setUserTranslationLanguage}
        userTranslationLanguageTwo={userTranslationLanguageTwo}
        setUserTranslationLanguageTwo={setUserTranslationLanguageTwo}
        selectAllPresent={selectAllPresent}
        setSelectAllPresent={setSelectAllPresent}
        selectAllNotPresent={selectAllNotPresent}
        setSelectAllNotPresent={setSelectAllNotPresent}
        handleDisplayNames={handleDisplayNames}
        getLocation={getLocation}
        defaultLocation={defaultLocation}
        singleChecked={singleChecked}
        setSingleChecked={setSingleChecked}
        singleCheckedTwo={singleCheckedTwo}
        setSingleCheckedTwo={setSingleCheckedTwo}
        multiChecked={multiChecked}
        setMultiChecked={setMultiChecked}
        setMultiCheckedTwo={setMultiCheckedTwo}
        multiCheckedTwo={multiCheckedTwo}
        tagsRequired={tagsRequired}
        setTagsRequired={setTagsRequired}
        setTagsRequiredTwo={setTagsRequiredTwo}
        tagsRequiredTwo={tagsRequiredTwo}
        tagsGridRef={tagsGridRef}
        tagsGridRefTwo={tagsGridRefTwo}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        langGridRef={langGridRef}
        langGridRefTwo={langGridRef}
        setLanguageRequired={setLanguageRequired}
        languageRequired={languageRequired}
        languageRequiredTwo={languageRequiredTwo}
        setLanguageRequiredTwo={setLanguageRequiredTwo}
        scrollStateLang={scrollStateLang}
        setScrollStateLang={setScrollStateLang}
        scrollStateLangTwo={scrollStateLangTwo}
        setScrollStateLangTwo={setScrollStateLangTwo}
        scrollStateTags={scrollStateTags}
        setScrollStateTags={setScrollStateTags}
        scrollStateTagsTwo={scrollStateTagsTwo}
        setScrollStateTagsTwo={setScrollStateTagsTwo}
        excludeNameChecked={excludeNameChecked}
        setExcludeNameChecked={setExcludeNameChecked}
        excludeNameCheckedtwo={excludeNameCheckedtwo}
        setExcludeNameCheckedTwo={setExcludeNameCheckedTwo}
        firstNameChecked={firstNameChecked}
        setFirstNameChecked={setFirstNameChecked}
        firstNameCheckedTwo={firstNameCheckedTwo}
        setFirstNameCheckedTwo={setFirstNameCheckedTwo}
        fullNameChecked={fullNameChecked}
        setFullNameChecked={setFullNameChecked}
        fullNameCheckedTwo={fullNameCheckedTwo}
        setFullNameCheckedTwo={setFullNameCheckedTwo}
        defaultEmailchecked={defaultEmailchecked}
        setDefualtEmailChecked={setDefualtEmailChecked}
        defaultEmailcheckedTwo={defaultEmailcheckedTwo}
        setDefualtEmailCheckedTwo={setDefualtEmailCheckedTwo}
        customEmailchecked={customEmailchecked}
        setCustomEmailChecked={setCustomEmailChecked}
        customEmailcheckedTwo={customEmailcheckedTwo}
        setCustomEmailCheckedTwo={setCustomEmailCheckedTwo}
        emailValue={emailValue}
        setEmailValue={setEmailValue}
        setEmailValueTwo={setEmailValueTwo}
        emailValueTwo={emailValueTwo}
        setEmailValidation={setEmailValidation}
        emailValidation={emailValidation}
        setEmailValidationTwo={setEmailValidationTwo}
        emailValidationTwo={emailValidationTwo}
        signatureValue={signatureValue}
        setSignatureValue={setSignatureValue}
        signatureValueTwo={signatureValueTwo}
        setSignatureValueTwo={setSignatureValueTwo}
        setAutoSelect={setAutoSelect}
        autoSelect={autoSelect}
        setCustomLengthValue={setCustomLengthValue}
        customLengthValue={customLengthValue}
        setCustomLengthValueTwo={setCustomLengthValueTwo}
        customLengthValueTwo={customLengthValueTwo}
      />
      {openUpdateModel && (
        <Dialog
          open={openUpdateModel}
          onClose={handleCloseUpdateModel}
          sx={{ "& .MuiDialog-paper": { width: "550px", height: "355px" } }}
        >
          <DialogTitle>
            <Box className={classes.dialogBox}>
              <IconButton
                autoFocus
                onClick={handleCloseUpdateModel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogBoxContent}>
            <WarningAmberOutlined
              sx={{ color: "#FAB431", height: "80px", width: "80px" }}
            />
            <Typography className={classes.dialogBoxHeading}>
              {t("Save_Changes")}
            </Typography>
            <Typography className={classes.dialogBoxText}>
              {t("save_location_changes")}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: "20px" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CommonButton
                  displayWhite="true"
                  onSubmit={handleCloseUpdateModel}
                  label={"No"}
                />

                <CommonButton
                  onSubmit={() => updateRule(undefined, undefined, true)}
                  label={"Yes"}
                  isLoading={updateLoading}
                  disabled={updateLoading || templateRequired}
                  className={classes.PostAiButton}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AddRule;
